import axios from 'axios';
import { API_URL, completeChallenge } from '../../../constants';
import { axiosConfig } from '../../../utils/axiosApi';
import { getErrorMessage } from '../../../utils/axiosErrorHandler';
import { getChallengeByIdForStudent } from '../../rootActions';

const PREFIX = '@WEB_CHALLENGES';

export const SUBMIT_CHALLENGE = PREFIX + 'SUBMIT_CHALLENGE';
export const SUBMIT_CHALLENGE_SUCCESS = PREFIX + 'SUBMIT_CHALLENGE_SUCCESS';
export const SUBMIT_CHALLENGE_FAILED = PREFIX + 'SUBMIT_CHALLENGE_FAILED';
export const SUBMIT_CHALLENGE_RESET = PREFIX + 'SUBMIT_CHALLENGE_RESET';
export const SUBMIT_TASK = PREFIX + 'SUBMIT_TASK';
export const SUBMIT_TASK_SUCCESS = PREFIX + 'SUBMIT_TASK_SUCCESS';
export const SUBMIT_TASK_FAILED = PREFIX + 'SUBMIT_TASK_FAILED';
export const SUBMIT_TASK_RESET = PREFIX + 'SUBMIT_TASK_RESET';
export const SUBMIT_COMPLETE_CHALLENGE_RESET = PREFIX + 'SUBMIT_COMPLETE_CHALLENGE_RESET';
export const CHALLENGES_STATUS = PREFIX + 'CHALLENGES_STATUS';
export const CHALLENGES_STATUS_SUCCESS = PREFIX + 'CHALLENGES_STATUS_SUCCESS';
export const CHALLENGES_STATUS_FAILED = PREFIX + 'CHALLENGES_STATUS_FAILED';

export const submitChallenge = information => async (dispatch: any) => {
    try {
        dispatch({ type: SUBMIT_CHALLENGE });
        const config = await axiosConfig(false);
        const res = await axios.put(
            `${API_URL}/challenges/complete?challengeId=${information.challengeId}&userId=${information.userId}`,
            null,
            config
        );
        if (res.status === 200) {
            if (res.data.responseDto !== null) {
                dispatch({ type: SUBMIT_CHALLENGE_SUCCESS, payload: res.data.responseDto });
            } else if (res.data.errorCode === 136 && res.data.responseDto === null) {
                dispatch({
                    type: SUBMIT_CHALLENGE_FAILED,
                    payload: getErrorMessage(res.data.errorDescription),
                });
            }
        } else {
            dispatch({ type: SUBMIT_CHALLENGE_FAILED, payload: res.statusText });
        }
    } catch (error) {
        dispatch({ type: SUBMIT_CHALLENGE_FAILED, payload: getErrorMessage(error) });
    }
};

export const submitTask =
    (submissionData, submitDate, studentId, challengeId, trackId, taskNo) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: SUBMIT_TASK });
            const submissionPayload = {
                submittedDate: submitDate,
                userId: studentId,
                challengeId: challengeId,
                taskNo: taskNo,
                trackId: trackId,
                answers: submissionData,
            };
            const config = await axiosConfig(false);
            const res = await axios.post(`${API_URL}/challenges/submit`, submissionPayload, config);
            await dispatch(getChallengeByIdForStudent(studentId, challengeId));
            dispatch({ type: SUBMIT_TASK_SUCCESS, payload: res.data.responseDto });
        } catch (error) {
            dispatch({ type: SUBMIT_TASK_FAILED, payload: getErrorMessage(error) });
        }
    };

export const getChallengesStatus = studentId => async (dispatch: any) => {
    try {
        dispatch({ type: CHALLENGES_STATUS });
        const config = await axiosConfig(false);
        const res = await axios.get(`${API_URL}/challenges/student?studentId=${studentId}`, config);
        dispatch({ type: CHALLENGES_STATUS_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: CHALLENGES_STATUS_FAILED, payload: getErrorMessage(error) });
    }
};

export const getStudentTrackChallengesStatus = (studentId: number | string, trackId: number) => async (dispatch: any) => {
    try {
        dispatch({ type: CHALLENGES_STATUS });
        const config = await axiosConfig(false);
        const res = await axios.get(`${API_URL}/challenges/track/student?studentId=${studentId}&trackId=${trackId}`, config);
        dispatch({ type: CHALLENGES_STATUS_SUCCESS, payload: res.data.responseDto });
    } catch (error) {
        dispatch({ type: CHALLENGES_STATUS_FAILED, payload: getErrorMessage(error) });
    }
};
