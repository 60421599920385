import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Badges from './Badges';
import { RootState } from '../../../../redux/rootReducer';
import { addSuccessStories } from '../../../../redux/webRedux/successStory/actions';
import {
    getStudentSpecificCertificateData,
    getTrackByChallengeStatus,
    getStudentEarnedCertificateData,
    getWebStudentById,
} from '../../../../redux/rootActions';
import { CertificateType } from '../../../../redux/certificates/reducers';
import { USER_PROFILE } from '../../../utils/storageWeb';
import { getChallengesStatus } from '../../../../redux/webRedux/challenges/actions';

import CertificateEarned from './CertifacteEarned';
import Tracks from './Tracks';
import StoryModal from '../../../../components/modals/addStoriesModal';

import CloseIcon from '../../../../assets/svg/CloseIcon';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useWidth from '../../../hooks/useWidth';
import styled from 'styled-components';
import Loader from '../../../../assets/svg/Loader';
import RepublishedChallengeMessageModal from '../../../modals/RepublishedChallengeMessageModal';
const OverviewLabel = styled.p`
    font-size: 10px;
`;

const DarkenedImage = styled.img`
    --tw-brightness: brightness(0.8);
`;

const BadgeCard = styled.div`
    width: 48%;
`;

const DashBoard = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const userProfile = useSelector((state: RootState) => state.authStudent.userProfile);

    const student = useSelector((state: RootState) => state.webUser.student);
    const studentsCertificateData = useSelector(
        (state: RootState) => state.certificates.studentCertificatesData
    );
    const earnedCertificates = useSelector(
        (state: RootState) => state.certificates.studentEarnedCertificatesData
    );
    const trackByChallengeStatusData = useSelector(
        (state: RootState) => state.tracks.trackByChallengeStatusData
    );
    const challengesStatus = useSelector((state: RootState) => state.webChallenge.challengesData);
    const isChallengesStatusLoading = useSelector(
        (state: RootState) => state.webChallenge.isChallengesStatusLoading
    );

    const [showStoryModal, setShowStoryModal] = React.useState(false);
    const successStoryAdded = useSelector((state: RootState) => state.webStory.success);
    const [selectedTab, setSelectedTab] = useState('onGoing');
    const [showTips, setShowTips] = useState(profile?.showLearnTip);
    const onGoingChallengesAvailable = false;
    const [showModal, setShowModal] = React.useState(false);
    const [addStoryBox, setAddStoryBox] = React.useState(true);
    const [totalCeritificates, setTotalceritificates] = useState<number>(0);
    const [totalBadges, setTotalBadges] = useState<number>(0);
    const [totalCompletedChallenge, setTotalCompletedChallenge] = useState<number>(0);
    const [totalOngoingChallenge, setTotalOngoingChallenge] = useState<number>(0);
    const [studentsCertificates, setStudentsCertificates] = useState<CertificateType[]>([]);
    const viewPortWidth = useWidth();

    const [republishedChallengeModal, setModal] = React.useState(false);
    const [challengeId, setSelectedChallengeId] = useState<any>();
    const [selectTrack, setSelectTrack] = useState<any>();
    const [selectedChallenge, setSelectedChallenge] = useState<any>();

    useEffect(() => {
        dispatch(getStudentSpecificCertificateData(userProfile?.userId));
        dispatch(getTrackByChallengeStatus(userProfile?.userId));
        //  dispatch(getStudentEarnedCertificateData(userProfile.userId));
        dispatch(getChallengesStatus(userProfile?.userId));
        dispatch(getWebStudentById(userProfile?.userId));
    }, [dispatch, userProfile]);

    useEffect(() => {
        if (student && challengesStatus) {
            setTotalceritificates(studentsCertificateData?.earned?.length);
            setTotalOngoingChallenge(challengesStatus.onGoing.length);
            setTotalCompletedChallenge(challengesStatus.completed.length);
            setTotalBadges(student.badges.length);
        }
    }, [student, challengesStatus, studentsCertificateData]);

    useEffect(() => {
        const allCertificates = studentsCertificateData?.earned?.concat(
            studentsCertificateData?.unearned?.inProgress,
            studentsCertificateData?.unearned?.notStarted
        );
        setStudentsCertificates(allCertificates);
    }, [studentsCertificateData]);

    const successStorySubmitHandler = (story, youtubeURL) => {
        const information = {
            content: story,
            userProfileId: student?.id,
            title: 'NoTitle',
            createdDate: moment().format('YYYY-MM-DD'),
            storyLink: youtubeURL,
            status: 'UNPUBLISHED',
        };
        dispatch(addSuccessStories(information));
    };

    const successStoryBoxhandler = () => {
        localStorage.setItem(USER_PROFILE, JSON.stringify({ ...profile, storyShow: false }));
        setAddStoryBox(false);
    };

    const doNotShowhandler = () => {
        localStorage.setItem(USER_PROFILE, JSON.stringify({ ...profile, showLearnTip: false }));
        setShowTips(false);
    };

    return (
        <>
            {viewPortWidth > 1023 ? (
                <div className="mx-10 md:mx-5 md:ml-14 lg:ml-72 lg:pl-3.5 lg:pt-2 md:mt-6 mb-12">
                    <RepublishedChallengeMessageModal
                        setShowModal={setModal}
                        showModal={republishedChallengeModal}
                        challengeId={challengeId}
                        selectTrack={selectTrack}
                        selectedChallenge={selectedChallenge}
                    />
                    <div className="font-bold font-manrope text-xl mb-1 ">
                        {student?.certificates.length === 0 ? 'Hey ' : 'Welcome back '}
                        {student?.firstName}!
                    </div>
                    <p className="mb-6">Start completing challenges to earn points and rewards!</p>
                    {(student?.SuccessStoryStatus === 'DELETED' ||
                        student?.SuccessStoryStatus === null) &&
                        addStoryBox &&
                        totalCompletedChallenge >= 40 && (
                            <div className=" mt-2 mb-6  bg-purple-85 w-full rounded-1 p-4">
                                <div className=" flex justify-between font-bold text-base">
                                    <div>
                                        <p className="font-semibold text-lg ">
                                            Share the success story about your internship!
                                        </p>
                                    </div>
                                    <div onClick={successStoryBoxhandler}>
                                        <CloseIcon size={20} color={'gray'} />
                                    </div>
                                </div>
                                <div>
                                    Congratulations on completing 40 challenges in the Future
                                    Careers BRIDGE program and finding an internship opportunity!
                                    Now you can share your success story with the world.
                                </div>

                                <div className="flex items-center rounded-b mt-5">
                                    <button
                                        className="w-36 h-12 text-purple-950 border-2 rounded border-purple-950 bg-purple-85 hover:shadow-xl font-bold px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                                        onClick={successStoryBoxhandler}
                                    >
                                        Not now
                                    </button>
                                    <button
                                        className="h-12 text-white border-2 rounded border-purple-950 bg-purple-950 hover:shadow-xl font-bold px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        onClick={() => {
                                            setShowStoryModal(true);
                                        }}
                                    >
                                        Add your Story
                                    </button>
                                </div>
                                <StoryModal
                                    showModal={showStoryModal}
                                    setShowModal={setShowStoryModal}
                                    successStorySubmitHandler={successStorySubmitHandler}
                                />
                            </div>
                        )}
                    {student?.SuccessStoryStatus === 'UNPUBLISHED' &&
                        addStoryBox &&
                        totalCompletedChallenge >= 40 && (
                            <div className=" mt-2 mb-6  bg-purple-85 w-full rounded-1 p-4">
                                <div className=" flex justify-between font-bold text-base">
                                    <div>
                                        <p className="font-semibold text-lg ">
                                            Your success story is submitted successfully!
                                        </p>
                                    </div>
                                    <div onClick={successStoryBoxhandler}>
                                        <CloseIcon size={20} color={'gray'} />
                                    </div>
                                </div>
                                <div>
                                    Thank you, your success story is submitted successfully. The
                                    admin team is reviewing it currently. They will reach out to you
                                    for clarifications if there is any.
                                </div>
                            </div>
                        )}

                    <div className="font-bold font-manrope text-xl mb-3">Overview</div>
                    {isChallengesStatusLoading ? (
                        <div className="flex flex-row mb-8 space-x-3">
                            <Loader />
                        </div>
                    ) : (
                        <div className="flex flex-row mb-8 space-x-3">
                            <div className="border-2 border-purple-200 rounded pl-4 py-4 w-72">
                                <img
                                    className="rounded"
                                    src={'/assets/earnedCertificate.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold  text-lg mb-0.5 mt-3">
                                    {totalCeritificates}
                                </p>
                                <p className="font-semibold  text-lg mb-0.5 mt-3">{`${totalCompletedChallenge}`}</p>
                                <p className="xl:whitespace-nowrap text-xs">
                                    Total number of Certificates earned
                                </p>
                            </div>

                            <div className="border-2 border-purple-200 rounded pl-4 py-4 w-72">
                                <img
                                    className="rounded "
                                    src={'/assets/onGoing.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold  text-lg mb-0.5 mt-3">{`${totalOngoingChallenge}`}</p>
                                <p className="xl:whitespace-nowrap text-xs">
                                    Total number of Ongoing Challenges
                                </p>
                            </div>

                            <div className="border-2 border-purple-200 rounded pl-4 py-4 w-72">
                                <img
                                    className="rounded"
                                    src={'/assets/completeChallenge.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold  text-lg mb-0.5 mt-3">{`${totalCompletedChallenge}`}</p>
                                <p className="xl:whitespace-nowrap text-xs">
                                    Total number of Challenges completed
                                </p>
                            </div>

                            <div className="border-2 border-purple-200 rounded pl-4 py-4 w-72">
                                <img
                                    className="rounded "
                                    src={'/assets/earnedBatch.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold  text-lg mb-0.5 mt-3">{`${totalBadges}`}</p>
                                <p className="xl:whitespace-nowrap text-xs">
                                    Total number of Badges earned
                                </p>
                            </div>
                        </div>
                    )}
                    <Badges />
                    {studentsCertificateData?.unearned?.notStarted.length +
                        studentsCertificateData?.earned.length >
                    0 ? (
                        <CertificateEarned
                            allCertificates={studentsCertificates}
                            earnedCertificates={earnedCertificates}
                            studentsCertificateData={studentsCertificateData}
                        />
                    ) : (
                        <div className=" mb-8">
                            <div className="font-bold font-manrope text-xl mb-3">Certificates</div>
                            <div className="bg-purple-85 flex justify-between rounded">
                                <div className="flex items-center py-10 px-6">
                                    <div className="space-y-5">
                                        <div className="text-justify">
                                            You have not earned any certificates yet. This is will
                                            be a great time to start working on your challenges and
                                            earn certificates!
                                        </div>
                                        <div className="flex space-x-4">
                                            <div
                                                onClick={() => {
                                                    history.push(`/dashBoard/help/start-track`);
                                                }}
                                                className="underline text-purple-950 flex justify-center items-center cursor-pointer whitespace-nowrap"
                                            >
                                                How to earn a certificate?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex pr-16 py-2">
                                    <div>
                                        <img
                                            className="h-full w-full"
                                            src={`/assets/emptyCertificates.png`}
                                            alt="logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Tracks
                        routerHistory={history}
                        profileData={profile}
                        tracksData={trackByChallengeStatusData}
                        setModal={setModal}
                        challengeId={challengeId}
                        setSelectedChallengeId={setSelectedChallengeId}
                        setSelectTrack={setSelectTrack}
                        selectTrack={selectTrack}
                        setSelectedChallenge={setSelectedChallenge}
                        selectedChallenge={selectedChallenge}
                    />
                    {showTips ? (
                        <div className="hidden md:block ">
                            <div className="bg-purple-85 flex justify-between">
                                <div className="flex items-center py-8 pl-8">
                                    <div className="space-y-5 ">
                                        <div className=" text-2xl-2 font-manrope font-bold ">
                                            Hey do you want to know how to start working on a career
                                            track?
                                        </div>
                                        <div className="trackTipSection text-justify">
                                            Check our support page now and start working on your
                                            very first track!
                                        </div>
                                        <div className="flex space-x-2">
                                            <div
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/dashBoard/help/start-track`,
                                                    });
                                                }}
                                                className="border-1 rounded bg-purple-950 text-white w-36 h-12 flex justify-center items-center font-semibold text-sm cursor-pointer"
                                            >
                                                Tips
                                            </div>
                                            <div
                                                onClick={doNotShowhandler}
                                                className="border-2 rounded border-purple-950 text-purple-950  w-48 h-12 flex justify-center items-center  cursor-pointer whitespace-nowrap"
                                            >
                                                Do not show anymore
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex pl-20">
                                    <div className="">
                                        <img
                                            style={{ height: '310px' }}
                                            className="hidden xl:block"
                                            src={`/assets/local/Learn_MyProgress/Start_working_track1.jpg`}
                                            alt="logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="pl-4 mt-6 mb-12 w-full">
                    <div className="font-bold font-manrope mb-1">
                        {student?.certificates.length === 0 ? 'Hey ' : 'Welcome back '}
                        {student?.firstName}!
                    </div>
                    <p className="mb-6 text-xs pr-8">
                        Start completing challenges to earn points and rewards!
                    </p>
                    {(student?.SuccessStoryStatus === 'DELETED' ||
                        student?.SuccessStoryStatus === null) &&
                        addStoryBox &&
                        totalCompletedChallenge >= 40 && (
                            <div className=" mt-2 mb-6  bg-purple-85 w-full rounded-1 p-4">
                                <div className="flex justify-between font-bold text-base">
                                    <div>
                                        <p className="font-semibold text-lg ">
                                            Share your success story
                                        </p>
                                    </div>
                                    <div onClick={successStoryBoxhandler}>
                                        <CloseIcon size={20} color={'gray'} />
                                    </div>
                                </div>
                                <div>
                                    Congratulations on completing your first ever track in Future
                                    Careers BRIDGE program! Now you can share your success story
                                    with the world.
                                </div>

                                <div className="flex items-center rounded-b mt-5">
                                    <button
                                        className="w-36 h-12 text-purple-950 border-2 rounded border-purple-950 bg-purple-85 hover:shadow-xl font-bold px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                                        onClick={successStoryBoxhandler}
                                    >
                                        Not now
                                    </button>
                                    <button
                                        className="h-12 text-white border-2 rounded border-purple-950 bg-purple-950 hover:shadow-xl font-bold px-6 py-2 text-sm cursor-pointer outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                        onClick={() => {
                                            setShowStoryModal(true);
                                        }}
                                    >
                                        Add your Story
                                    </button>
                                </div>
                                <StoryModal
                                    showModal={showStoryModal}
                                    setShowModal={setShowStoryModal}
                                    successStorySubmitHandler={successStorySubmitHandler}
                                />
                            </div>
                        )}
                    <div className="font-bold font-manrope mb-3">Overview</div>
                    {isChallengesStatusLoading ? (
                        <div className="mb-8">
                            <Loader />
                        </div>
                    ) : (
                        <div className="flex flex-row mb-8 gap-x-1 gap-y-1 flex-wrap">
                            <BadgeCard className="border-2 border-purple-200 rounded pl-2 py-4">
                                <img
                                    className="rounded"
                                    src={'/assets/earnedCertificate.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold mb-0.5 mt-3">{`${totalCeritificates}`}</p>
                                <OverviewLabel className="xl:whitespace-nowrap">
                                    Total number of Certificates earned
                                </OverviewLabel>
                            </BadgeCard>

                            <BadgeCard className="border-2 border-purple-200 rounded pl-2 py-4">
                                <img
                                    className="rounded "
                                    src={'/assets/onGoing.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold mb-0.5 mt-3">{`${totalOngoingChallenge}`}</p>
                                <OverviewLabel className="xl:whitespace-nowrap">
                                    Total number of Ongoing Challenges
                                </OverviewLabel>
                            </BadgeCard>

                            <BadgeCard className="border-2 border-purple-200 rounded pl-2 py-4">
                                <img
                                    className="rounded"
                                    src={'/assets/completeChallenge.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold mb-0.5 mt-3">{`${totalCompletedChallenge}`}</p>
                                <OverviewLabel className="xl:whitespace-nowrap">
                                    Total number of Challenges completed
                                </OverviewLabel>
                            </BadgeCard>

                            <BadgeCard className="border-2 border-purple-200 rounded pl-2 py-4">
                                <img
                                    className="rounded "
                                    src={'/assets/earnedBatch.png'}
                                    alt="profile"
                                />
                                <p className="font-semibold mb-0.5 mt-3">{`${totalBadges}`}</p>
                                <OverviewLabel className="xl:whitespace-nowrap">
                                    Total number of Badges earned
                                </OverviewLabel>
                            </BadgeCard>
                        </div>
                    )}
                    <Badges />
                    {studentsCertificateData?.unearned?.inProgress.length +
                        studentsCertificateData?.earned.length >
                    0 ? (
                        <CertificateEarned
                            allCertificates={studentsCertificates}
                            earnedCertificates={earnedCertificates}
                            studentsCertificateData={studentsCertificateData}
                        />
                    ) : (
                        <div className=" mb-8">
                            <div className="font-bold font-manrope mb-3">Certificates</div>
                            <div className="bg-purple-85 flex justify-between rounded">
                                <div className="flex flex-col gap-y-3 p-6">
                                    <div>
                                        <img
                                            className="w-44"
                                            src={`/assets/emptyCertificates.png`}
                                            alt="logo"
                                        />
                                    </div>
                                    <div className="text-justify text-xs">
                                        You have not earned any certificates yet. This is will be a
                                        great time to start working on your challenges and earn
                                        certificates!
                                    </div>
                                    <div
                                        onClick={() => {
                                            history.push(`/dashBoard/help/start-track`);
                                        }}
                                        className="underline text-purple-950 cursor-pointer text-xs"
                                    >
                                        How to earn a certificate?
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Tracks
                        routerHistory={history}
                        profileData={profile}
                        tracksData={trackByChallengeStatusData}
                        setModal={setModal}
                        setSelectedChallengeId={setSelectedChallengeId}
                        challengeId={challengeId}
                        setSelectTrack={setSelectTrack}
                        selectTrack={selectTrack}
                        setSelectedChallenge={setSelectedChallenge}
                        selectedChallenge={selectedChallenge}
                    />
                    {showTips ? (
                        <>
                            <div>
                                <div className="bg-purple-85 w-full flex flex-col justify-between">
                                    <DarkenedImage
                                        className="h-full object-contain filter"
                                        src={`/assets/local/Learn_MyProgress/Start_working_track1.jpg`}
                                        alt="logo"
                                    />
                                    <div className="flex flex-col gap-y-2 px-5 py-6">
                                        <div className="text-xl font-manrope font-bold">
                                            Hey do you want to know how to start working on a career
                                            track?
                                        </div>

                                        <div className="text-justify text-xs w-full">
                                            Check our support page now and start working on your
                                            very first track!
                                        </div>
                                        <div className="flex flex-row gap-x-4 mt-4">
                                            <div
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/dashBoard/help/start-track`,
                                                    });
                                                }}
                                                className="rounded bg-purple-950 text-white h-10 px-4 flex justify-center items-center font-semibold text-sm cursor-pointer"
                                            >
                                                Tips
                                            </div>
                                            <div
                                                onClick={doNotShowhandler}
                                                className="border-2 rounded border-purple-950 text-purple-950 h-10 w-48 flex justify-center items-center  cursor-pointer whitespace-nowrap"
                                            >
                                                Do not show anymore
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default DashBoard;
