import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import S3 from 'react-aws-s3';
import React, { ReactElement, useEffect, useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import Modal from 'react-modal';
import {
    editWebUser,
    getInstitutesPublicData,
    getTags,
    getWebStudentById,
    getCompanyPublicData,
    generateOTP,
    sendMobileVerification,
    RESET_SEND_MOBILE_VERIFICATION,
    verifyEmailOnboard,
    logoutWeb,
} from '../../../../../../redux/rootActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/rootReducer';
import { InstitutesType } from '../../../../../../redux/institutes/reducers';
import { USER_PROFILE } from '../../../../../utils/storageWeb';
import { checkNIC, checkAddress, mobielNumberVerification } from '../../../../../utils/';
import moment from 'moment';
import { moduleNames, s3Config, s3UrlPrefix } from '../../../../../../constants';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import VerifyPhoneNumberModal from '../../../../../modals/VerifyPhoneNumberModal';
import Verified from '../../../../../../assets/svg/Verified';
import MobileNotVerifiedModal from '../../../../../modals/MobileNotVerifiedModal';
import Loader from '../../../../../../assets/svg/Loader';
import useWidth from '../../../../../hooks/useWidth';
import CheckedCircle from '../../../../../../assets/svg/CheckedCirlce';
import LockCircle from '../../../../../../assets/svg/LockCircle';
import styled from 'styled-components';
import TickCircle from '../../../../../../assets/svg/TickCircle';
import { Redirect, useHistory } from 'react-router-dom';

const SmallFontLabel = styled.label`
    font-size: 10px;
`;

const SmallFontDiv = styled.div`
    font-size: 10px;
`;

export default function OnBoard(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = JSON.parse(localStorage.getItem(USER_PROFILE));
    const studentData = useSelector((state: RootState) => state.webUser.student);
    const isEditLoading = useSelector((state: RootState) => state.webUser.isEditLoading);
    const StudentUpdateSuccessMessage = useSelector(
        (state: RootState) => state.webUser.StudentUpdateSuccessMessage
    );
    const emailVerificationSuccessMessage = useSelector(
        (state: RootState) => state.webUser.emailVerificationSuccessMessage
    );
    const tagData = useSelector((state: RootState) => state.tags.tagData);
    const [interestTag, setInterestTag] = useState<any>();
    const instituteData = useSelector((state: RootState) => state.institutes.publicInstitutData);
    const companyData = useSelector((state: RootState) => state.companies.companyPublicData);
    const otpResponse = useSelector((state: RootState) => state.newWebUser.otpResponse);
    const mobileLoginError = useSelector((state: RootState) => state.newWebUser.mobileLoginError);
    const isOtpSent = useSelector((state: RootState) => state.newWebUser.otpSent);
    const sendMobileVerificationSuccess = useSelector(
        (state: RootState) => state.webUser.sendMobileVerificationSuccess
    );
    const sendMobileVerificationFailed = useSelector(
        (state: RootState) => state.webUser.sendMobileVerificationError
    );
    const [institue, setInstitute] = useState<string>(null);
    const [company, setCompany] = useState<string>(null);
    const [otherCompany, setOtherCompany] = useState<string>(null);
    const [institueID, setInstituteID] = useState<any>(null);
    const [isCat1, setIsCat1] = useState(false);
    const [isCat2, setIsCat2] = useState(false);
    const [isCat3, setIsCat3] = useState(false);

    const [categoryName1, setCategoryName1] = useState<string[]>(null);
    const [categoryName2, setCategoryName2] = useState<string[]>(null);
    const [categoryName3, setCategoryName3] = useState<string[]>(null);

    const [instCategory1, setInstCategory1] = useState<any>(null);
    const [instCategory2, setInstCategory2] = useState<any>(null);
    const [instCategory3, setInstCategory3] = useState<any>(null);

    const [institueCat1DropDownVisible, setInstitueCat1DropDownVisible] = useState(false);
    const [cat1FilterAction, setcat1FilterAction] = useState<string>('Select');
    const [institueCat2DropDownVisible, setInstitueCat2DropDownVisible] = useState(false);
    const [cat2FilterAction, setcat2FilterAction] = useState<string>('Select');
    const [institueCat3DropDownVisible, setInstitueCat3DropDownVisible] = useState(false);
    const [cat3FilterAction, setcat3FilterAction] = useState<string>('Select');

    const [selectedInstitue, setSelectedInstitue] = useState<InstitutesType[]>([]);
    const [showModal, setShowModal] = React.useState(false);
    const [onBoardFlow, setOnboardFlow] = useState('personal-details');
    const [secondaryEd, setSecondaryEd] = useState(null);
    const [isEmployed, setIsEmployed] = useState(null);
    const [joinedFutureCareerBridgeReason, setJoinedFutureCareerBridgeReason] = useState(null);
    const [academicOnly, setAcademicOnly] = useState(false);

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [companyDropdownVisible, setCompanyDropdownVisible] = useState(false);
    const [hometownDropdownVisible, setHometownDropdownVisible] = useState(false);
    const [isPursuingHigherEducation, setIsPursuingHigherEducation] = useState(null);
    const [genderDropdownVisible, setGenderDropdownVisible] = useState(false);
    const [trackFilterAction, setTrackFilterAction] = useState<string>('Select your institute');
    const [companyFilterAction, setCompanyFilterAction] = useState<string>('Select your company');
    const [trackFilterAddressAction, setTrackFilterAddressAction] =
        useState<string>('Select your home town');
    const [trackFilterGenderAction, setTrackFilterGenderAction] =
        useState<string>('Select your gender');
    const [interestedTags, setInterestedTags] = useState([]);
    const [bridgeNumber, setBridgeNumber] = useState<any>();
    const [batchNumber, setBatchNumber] = useState<any>();
    const [stream, setStream] = useState<any>(null);
    const [dateOfBirth, setDateOfBirth] = useState<Date>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [nicNumber, setNICNumber] = useState<string>();
    const [mobileNumber, setMobileNumber] = useState<any>();
    const [email, setEmail] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [educationStatus, setEducationStatus] = useState<any>(secondaryEd);

    const [isErrorFirstName, setIsErrorFirstName] = useState<boolean>(false);
    const [isErrorLastName, setIsErrorLastName] = useState<boolean>(false);
    const [isErrorDOB, setIsErrorDOB] = useState<boolean>(false);
    const [isErrorGender, setIsErrorGender] = useState<boolean>(false);
    const [isErrorMobileNo, setIsErrorMobileNo] = useState<boolean>(false);
    const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
    const [isErrorHomeTown, setIsErrorHomeTown] = useState<boolean>(false);
    const [isErrorAddress, setIsErrorAddress] = useState<boolean>(false);
    const [isErrorInterestedField, setIsErrorInterestedField] = useState<boolean>(false);
    const [isErrorNIC, setIsErrorNIC] = useState<boolean>(false);

    const [belowHometown, setBelowHometown] = useState<number>();
    const [completeEducation, setCompleteEducation] = useState(false);
    const [emailHelpText, setEmailHelpText] = useState<boolean>(false);
    const emailCreationURL = 'https://www.youtube.com/watch?v=WkUGuBpedfI';

    const [imageUrl, setImageUrl] = useState(null);
    const [imageLocation, setImageLocation] = useState('');
    const [googleImage, setGoogleImage] = useState<any>();
    const [enablePublicProfile, setEnablePublicProfile] = useState(true);
    const [selectedTags, setselectedTags] = useState([]);

    const [category1, setCategory1] = useState<any>(null);
    const [category2, setCategory2] = useState<any>(null);
    const [category3, setCategory3] = useState<any>(null);

    const [isErrorEducationStatus, setIsErrorEducationStatus] = useState<boolean>(false);
    const [isErrorInstituteName, setIsErrorInstituteName] = useState<boolean>(false);
    const [isErrorInstituteRegId, setIsErrorInstituteRegId] = useState<boolean>(false);

    const [isErrorPursueHigherEducation, setIsErrorPursueHigherEducation] =
        useState<boolean>(false);
    const [isErrorrReasonForFCB, setIsErrorrReasonForFCB] = useState<boolean>(false);
    const [isErrorEmployed, setIsErrorEmployed] = useState<boolean>(false);

    const [isErrorStream, setIsErrorStream] = useState<boolean>(false);
    const [isErrorCompany, setIsErrorCompany] = useState<boolean>(false);
    const [isErrorOtherCompany, setIsErrorOtherCompany] = useState<boolean>(false);

    const [streamDropdownVisible, setStreamDropdownVisible] = useState<boolean>(false);
    const [AlStreamFilterAction, setAlStreamFilterAction] = useState<any>('Select your stream');

    const [pursuingHigherStudies, setPursuingHigherStudies] = useState<any>();

    const [verifyPhoneNumber, setVerifyPhoneNumber] = useState<boolean>(false);
    const [verifyNumberShowModal, setVerifyNumberShowModal] = React.useState(false);
    const [isMobileError, setIsMobileError] = useState<boolean>(false);
    const [isPublic, setIsPublic] = useState(true);

    const [mobileNotVerifiedShowModal, setMobileNotVerifiedShowModal] = React.useState(false);

    const [verifyPhoneNumberSuccess, setVerifyPhoneNumberSuccess] = useState<boolean>(false);
    const viewPortWidth = useWidth();

    const TrackAddressFilter = [
        { id: 0, val: 'Ampara' },
        { id: 1, val: 'Anuradhapura' },
        { id: 3, val: 'Batticaloa' },
        { id: 4, val: 'Badulla' },
        { id: 5, val: 'Colombo' },
        { id: 6, val: 'Gampaha' },
        { id: 7, val: 'Galle' },
        { id: 8, val: 'Hambantota' },
        { id: 9, val: 'Jaffna' },
        { id: 10, val: 'Kalutara' },
        { id: 11, val: 'Kurunegala' },
        { id: 12, val: 'Kilinochchi' },
        { id: 13, val: 'Kandy' },
        { id: 14, val: 'Kegalle' },
        { id: 15, val: 'Polonnaruwa' },
        { id: 16, val: 'Mannar' },
        { id: 17, val: 'Mullaitivu' },
        { id: 18, val: 'Matale' },
        { id: 19, val: 'Matara' },
        { id: 20, val: 'Monaragala' },
        { id: 21, val: 'Nuwara Eliya' },
        { id: 22, val: 'Puttalam' },
        { id: 23, val: 'Ratnapura' },
        { id: 24, val: 'Trincomalee' },
        { id: 25, val: 'Vavuniya' },
    ];

    const TrackGenderFilter = [
        { id: 0, val: 'Male' },
        { id: 1, val: 'Female' },
        { id: 2, val: 'Other' },
    ];

    const AlStreamFilter = [
        { id: 0, val: 'Science' },
        { id: 1, val: 'Mathematics' },
        { id: 2, val: 'Commerce' },
        { id: 2, val: 'Arts' },
        { id: 2, val: 'Technical' },
    ];

    const customStyles: Modal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            backdropFilter: 'blur(7px)',
            overflow: 'auto',
            zIndex: 999,
        },
        content: {
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            border: '0px',
            backgroundColor: 'transparent',
            zIndex: 999,
        },
    };

    const validationSchemaOnboard = Yup.object({
        email: Yup.string()
            .trim()
            .email('Email must be a valid email')
            .required('Email address is required'),
    });

    useEffect(() => {
        if (studentData?.isMobileVerified) {
            setVerifyPhoneNumberSuccess(true);
        }
    }, [studentData]);

    useEffect(() => {
        if (mobileNumber?.length > 0) {
            setVerifyPhoneNumber(true);
        }
    }, [mobileNumber]);

    useEffect(() => {
        if (cat1FilterAction !== 'Select') {
            setCategory1(cat1FilterAction);
        }

        if (cat2FilterAction !== 'Select') {
            setCategory2(cat2FilterAction);
        }

        if (cat3FilterAction !== 'Select') {
            setCategory3(cat3FilterAction);
        }
    }, [cat1FilterAction, cat2FilterAction, cat3FilterAction]);

    useEffect(() => {
        if (tagData?.length > 0) {
            setInterestTag(
                tagData?.filter(
                    tag =>
                        tag.tagCategory.toLowerCase().replace(/\s/g, '') === 'interest' ||
                        tag.tagCategory.toLowerCase().replace(/\s/g, '') === 'interests'
                )
            );
        }
    }, [tagData]);

    useEffect(() => {
        setEducationStatus(secondaryEd);
    }, [secondaryEd]);

    useEffect(() => {
        setselectedTags(interestedTags?.map(action => action.tagName));
    }, [interestedTags]);

    useEffect(() => {
        if (hometownDropdownVisible === true) {
            const height = document.getElementById('hometwonId')?.clientHeight;
            setBelowHometown(height + 20);
        } else {
            setBelowHometown(15);
        }
    }, [hometownDropdownVisible]);

    const PersonalDetailsCheck = async () => {
        const readyToNext = await dispatch(verifyEmailOnboard(email));
        if (['', null, undefined].includes(firstName)) {
            setIsErrorFirstName(true);
        } else if (['', null, undefined].includes(lastName)) {
            setIsErrorLastName(true);
        } else if (dateOfBirth === undefined) {
            setIsErrorDOB(true);
        } else if (
            trackFilterGenderAction === 'Select your gender' ||
            trackFilterGenderAction === undefined
        ) {
            setIsErrorGender(true);
            // removing nic logc
            // } else if (['', null, undefined].includes(nicNumber)) {
            //     setIsErrorNIC(true);
        } else if (mobielNumberVerification(mobileNumber)) {
            setIsErrorMobileNo(true);
        } else if (['', null, undefined].includes(email)) {
            setIsErrorEmail(true);
        } else if (
            trackFilterAddressAction === 'Select your home town' ||
            trackFilterAddressAction === undefined
        ) {
            setIsErrorHomeTown(true);
        } else if (['', null, undefined].includes(address)) {
            setIsErrorAddress(true);
        } else if (!(verifyPhoneNumberSuccess || studentData?.contact?.mobileNo)) {
            setMobileNotVerifiedShowModal(true);
            // eslint-disable-next-line prettier/prettier
        } else if (
            !readyToNext &&
            (studentData?.emailId === null || studentData?.emailId === undefined)
        ) {
            setIsErrorEmail(true);
        } else {
            setOnboardFlow('education');
        }
    };

    const educationDetailsChecked = () => {
        if (secondaryEd === null) {
            setIsErrorEducationStatus(true);
        } else if (
            secondaryEd !== 'I have not done O/Ls yet' &&
            secondaryEd !== 'I have completed O/Ls but not A/Ls' &&
            stream === null
        ) {
            setIsErrorStream(true);
        } else if (
            secondaryEd !== 'I have not done O/Ls yet' &&
            isPursuingHigherEducation === null
        ) {
            setIsErrorPursueHigherEducation(true);
        } else if (isPursuingHigherEducation === 'Yes' && !institue) {
            setIsErrorInstituteName(true);
        } else if (isPursuingHigherEducation === 'Yes' && !batchNumber) {
            setIsErrorInstituteRegId(true);
        } else if (joinedFutureCareerBridgeReason === null) {
            setIsErrorrReasonForFCB(true);
        } else if (isEmployed === null) {
            setIsErrorEmployed(true);
        } else if (
            (companyFilterAction === 'Select your company' ||
                companyFilterAction === 'Select a company') &&
            isEmployed === 'Yes, at a company'
        ) {
            setIsErrorCompany(true);
        } else if ((company === null || company === '') && companyFilterAction === 'Others') {
            setIsErrorOtherCompany(true);
        } else {
            setOnboardFlow('field-subscribe');
            setCompleteEducation(true);
        }
    };

    const subYears = years => {
        return new Date().setFullYear(new Date().getFullYear() - years);
    };

    const handleImage = (e: any) => {
        const _URL = window.URL || window.webkitURL;
        let file: File, img;
        if ((file = e?.target?.files[0])) {
            img = new Image();
            img.src = _URL.createObjectURL(file);
            setImageUrl(img.src);
        }
    };

    const deleteImageFromS3 = async () => {
        const imageName = imageUrl;
        // s3Config.dirName = `webUsers/${imageUrl}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.deleteFile(imageName)
            .then(() => {
                setImageUrl('');
                setImageLocation('');
            })
            .catch(err => {
                setImageUrl('');
                setImageLocation('');
                console.error(err);
            });
    };
    const uploadImageToS3 = async e => {
        const imageName = Date.now().toString();
        s3Config.dirName = `webUsers/${imageName}`;
        const ReactS3Client = new S3(s3Config);
        ReactS3Client.uploadFile(e.target.files[0], imageName)
            .then(data => {
                setImageLocation(data.key);
            })
            .catch(err => {
                console.error(err);
            });
    };

    useEffect(() => {
        setFirstName(studentData?.firstName);
        setBatchNumber(studentData?.institute?.studentId);
        setLastName(studentData?.lastName);
        setNICNumber(studentData?.nic);
        setEmail(studentData?.emailId);
        setAddress(studentData?.contact?.address1);
        setMobileNumber(studentData?.contact?.mobileNo);
        if (studentData?.birthDate !== null && studentData?.birthDate !== undefined) {
            setDateOfBirth(new Date(studentData?.birthDate));
        }
        if (studentData?.imageUrl) {
            setImageUrl(studentData?.imageUrl);
            setImageLocation(studentData?.imageUrl);
        }
        if (studentData?.enablePublicProfile) {
            setEnablePublicProfile(studentData?.enablePublicProfile);
        }
        if (studentData?.contact?.city) {
            setTrackFilterAddressAction(studentData?.contact?.city);
        }

        if (studentData?.institute?.orgName) {
            setTrackFilterAction(studentData?.institute?.orgName);
        }
        if (studentData?.gender) {
            setTrackFilterGenderAction(studentData?.gender);
        }
    }, [studentData]);

    useEffect(() => {
        dispatch(getInstitutesPublicData());
        dispatch(getCompanyPublicData());
    }, [dispatch]);

    useEffect(() => {
        if (interestedTags?.length > 2) {
            setIsErrorInterestedField(false);
        }
    }, [interestedTags]);

    useEffect(() => {
        setSelectedInstitue(instituteData?.filter(data => data?.instName === institue));
    }, [institue, instituteData]);

    useEffect(() => {
        if (
            selectedInstitue
                ?.map(
                    data =>
                        data?.instCategory1 !== null &&
                        data?.instCategory1?.name !== '' &&
                        data?.instCategory1?.name !== undefined
                )
                .includes(true)
        ) {
            setCategoryName1(selectedInstitue.map(data => data?.instCategory1?.name));
            setIsCat1(true);
        } else {
            setIsCat1(false);
        }
        if (
            selectedInstitue
                ?.map(
                    data =>
                        data?.instCategory2 !== null &&
                        data?.instCategory2?.name !== '' &&
                        data?.instCategory2?.name !== undefined
                )
                .includes(true)
        ) {
            setCategoryName2(selectedInstitue.map(data => data?.instCategory2?.name));
            setIsCat2(true);
        } else {
            setIsCat2(false);
        }
        if (
            selectedInstitue
                ?.map(
                    data =>
                        data?.instCategory3 !== null &&
                        data?.instCategory3?.name !== '' &&
                        data?.instCategory3?.name !== undefined
                )
                .includes(true)
        ) {
            setCategoryName3(selectedInstitue.map(data => data?.instCategory3?.name));
            setIsCat3(true);
        } else {
            setIsCat3(false);
        }
    }, [selectedInstitue]);

    useEffect(() => {
        if (categoryName1 !== null) {
            setInstCategory1({
                name: categoryName1[0],
                option: category1,
            });
        } else {
            setInstCategory1(null);
        }

        if (categoryName2 !== null) {
            setInstCategory2({
                name: categoryName2[0],
                option: category2,
            });
        } else {
            setInstCategory2(null);
        }

        if (categoryName3 !== null) {
            setInstCategory3({
                name: categoryName3[0],
                option: category3,
            });
        } else {
            setInstCategory3(null);
        }
    }, [category1, category2, category3, categoryName1, categoryName2, categoryName3]);

    const PersonalDetailsCheckFinal = values => {
        if (interestedTags?.length < 3) {
            setIsErrorInterestedField(true);
        } else {
            if (institueID !== null) {
                dispatch(
                    editWebUser(profile?.userId, {
                        bridgeInvitationCode: bridgeNumber,
                        userName: values.email,
                        firstName: firstName,
                        lastName: lastName,
                        birthDate: moment(dateOfBirth).format('YYYY-MM-DD'),
                        gender: trackFilterGenderAction,
                        nic: nicNumber,
                        status: 'ACTIVE',
                        contact: {
                            mobileNo: mobileNumber || studentData?.contact?.mobileNo,
                            city: trackFilterAddressAction,
                            address1: address,
                        },

                        emailId: values.email,

                        institute: {
                            id: institueID,
                            studentId: batchNumber,
                            instCategory1: instCategory1,
                            instCategory2: instCategory2,
                            instCategory3: instCategory3,
                        },
                        tags: interestedTags,
                        roles: [
                            {
                                roleName: 'STUDENT',
                            },
                        ],
                        educationStatus: educationStatus,
                        alStream: stream,
                        pursuingHigherStudies: pursuingHigherStudies,
                        reasonForJoin: joinedFutureCareerBridgeReason,
                        empStatus: isEmployed,
                        companyName: company,
                        enablePublicProfile: enablePublicProfile,
                        isPublic: isPublic,
                        imageUrl: imageLocation,
                        onBoardingCompleted: true,
                        welcomeTour: false,
                    })
                );
            } else {
                dispatch(
                    editWebUser(profile?.userId, {
                        bridgeInvitationCode: bridgeNumber,
                        userName: values.email,
                        firstName: firstName,
                        lastName: lastName,
                        birthDate: moment(dateOfBirth).format('YYYY-MM-DD'),
                        gender: trackFilterGenderAction,
                        nic: nicNumber,
                        status: 'ACTIVE',
                        contact: {
                            mobileNo: mobileNumber || studentData?.contact?.mobileNo,
                            city: trackFilterAddressAction,
                            address1: address,
                        },

                        emailId: values.email,

                        institute: null,
                        tags: interestedTags,
                        roles: [
                            {
                                roleName: 'STUDENT',
                            },
                        ],
                        educationStatus: educationStatus,
                        alStream: stream,
                        pursuingHigherStudies: pursuingHigherStudies,
                        reasonForJoin: joinedFutureCareerBridgeReason,
                        empStatus: isEmployed,
                        companyName: company,
                        enablePublicProfile: enablePublicProfile,
                        isPublic: isPublic,
                        imageUrl: imageLocation,
                        onBoardingCompleted: true,
                        welcomeTour: false,
                    })
                );
            }
            setShowModal(true);
        }
    };

    useEffect(() => {
        if (profile?.userId) {
            dispatch(getWebStudentById(profile?.userId));
        }
        dispatch(getTags(false, moduleNames.Tracks));
    }, [dispatch]);

    const sendOTP = () => {
        dispatch(sendMobileVerification(profile?.userId, mobileNumber));
    };

    useEffect(() => {
        if (sendMobileVerificationSuccess && mobileNumber) {
            setVerifyNumberShowModal(true);
        } else if (sendMobileVerificationFailed) {
            setIsMobileError(true);
        }
    }, [sendMobileVerificationFailed, sendMobileVerificationSuccess, mobileNumber]);

    useEffect(() => {
        if (sendMobileVerificationFailed) {
            setIsErrorMobileNo(true);
        }
    }, [sendMobileVerificationFailed]);

    useEffect(() => {
        dispatch({ type: RESET_SEND_MOBILE_VERIFICATION });
    }, [dispatch, sendMobileVerificationSuccess]);

    // Ridirect User when user has no authorization or user has already registered
    if (!profile) {
        return <Redirect to={'/signin'} />;
    } else if (studentData?.onBoardingCompleted) {
        // return <Redirect to={'/dashBoard/learn'} />;
    }
    return (
        <>
            {viewPortWidth > 1023 ? (
                <>
                    <div className="grid grid-cols-12">
                        <div className="col-span-3 grid grid-cols-12">
                            <div className="col-span-2 border-r-2"></div>
                            <div className="col-span-10 border-r-2">
                                <div className="ml-5 font-bold font-manrope text-2xl-2 mt-10">
                                    Complete Your account
                                </div>
                                <ul className="vertical-line mt-8">
                                    <li className="">
                                        <div>
                                            <input
                                                id="radio-1"
                                                className={` ${
                                                    onBoardFlow === 'personal-details'
                                                        ? 'radio-custom'
                                                        : 'radio-success-custom'
                                                }`}
                                                name="radio-group"
                                                type="radio"
                                                value="personal-details"
                                                checked={onBoardFlow === 'personal-details'}
                                                onChange={e => {
                                                    setOnboardFlow(e.target.value);
                                                }}
                                                disabled
                                            />
                                            <label
                                                htmlFor="radio-1"
                                                className={` ${
                                                    onBoardFlow === 'personal-details'
                                                        ? 'text-purple-950 font-semibold radio-custom-label'
                                                        : 'text-purple-950 font-semibold radio-success-custom-label'
                                                }`}
                                            >
                                                Personal Details
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <input
                                                id="radio-2"
                                                className={` ${
                                                    onBoardFlow === 'education'
                                                        ? 'radio-custom'
                                                        : completeEducation
                                                        ? 'radio-success-custom'
                                                        : 'radio-locked-custom'
                                                }`}
                                                name="radio-group"
                                                type="radio"
                                                value="education"
                                                checked={onBoardFlow === 'education'}
                                                onChange={e => {
                                                    setOnboardFlow(e.target.value);
                                                }}
                                                disabled
                                            />
                                            <label
                                                htmlFor="radio-2"
                                                className={` ${
                                                    onBoardFlow === 'education'
                                                        ? 'text-purple-950 font-semibold radio-custom-label'
                                                        : completeEducation
                                                        ? 'text-purple-950 font-semibold radio-success-custom-label'
                                                        : 'text-purple-950 font-semibold radio-locked-custom-label'
                                                }`}
                                            >
                                                Educational Details
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <input
                                                id="radio-3"
                                                className={` ${
                                                    onBoardFlow === 'field-subscribe'
                                                        ? 'radio-custom'
                                                        : 'radio-locked-custom'
                                                }`}
                                                name="radio-group"
                                                type="radio"
                                                value="field-subscribe"
                                                checked={onBoardFlow === 'field-subscribe'}
                                                onChange={e => {
                                                    setOnboardFlow(e.target.value);
                                                }}
                                                disabled
                                            />
                                            <label
                                                htmlFor="radio-3"
                                                className={`radio-custom-label ${
                                                    onBoardFlow === 'field-subscribe'
                                                        ? 'text-purple-950 font-semibold radio-custom-label'
                                                        : 'text-purple-950 font-semibold radio-locked-custom-label'
                                                }`}
                                            >
                                                Interests and Skills
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-span-9 ml-5 md:mr-20 w-full">
                            <Formik
                                enableReinitialize
                                validationSchema={validationSchemaOnboard}
                                initialValues={{ email: studentData?.emailId, password: '' }}
                                onSubmit={async values => {
                                    PersonalDetailsCheckFinal(values);
                                }}
                            >
                                {({ handleSubmit, setFieldValue, values, errors }) => (
                                    <>
                                        {onBoardFlow === 'personal-details' ? (
                                            <>
                                                <MobileNotVerifiedModal
                                                    showModal={mobileNotVerifiedShowModal}
                                                    setShowModal={setMobileNotVerifiedShowModal}
                                                    setOnClickSubmit={() => sendOTP()}
                                                />
                                                <VerifyPhoneNumberModal
                                                    mobileNumber={mobileNumber}
                                                    showModal={verifyNumberShowModal}
                                                    setShowModal={setVerifyNumberShowModal}
                                                    setVerifyPhoneNumberSuccess={
                                                        setVerifyPhoneNumberSuccess
                                                    }
                                                />
                                                <div className="font-bold font-manrope text-2xl-2 mt-10">
                                                    Personal Details
                                                </div>
                                                <div className="flex flex-row mb-8 mt-6">
                                                    <div className="flex flex-row items-end w-full ">
                                                        <img
                                                            className="rounded-full h-36 w-36"
                                                            src={`${
                                                                imageUrl === null
                                                                    ? '/assets/user.png'
                                                                    : imageUrl.includes('webUsers')
                                                                    ? s3UrlPrefix + imageUrl
                                                                    : imageUrl
                                                            }`}
                                                            alt="profile"
                                                        />
                                                        <div>
                                                            <div
                                                                className="flex flex-col ml-6"
                                                                style={{
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <label
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                    }}
                                                                    className="border-2 cursor-pointer font-semibold border-purple-800 bg-white text-purple-800 rounded w-40 h-10"
                                                                    htmlFor="files"
                                                                >
                                                                    Upload picture
                                                                </label>
                                                                <input
                                                                    id="files"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    className="hidden"
                                                                    onChange={e => {
                                                                        const file = [];
                                                                        file.push(e);
                                                                        handleImage(e);
                                                                        uploadImageToS3(e);
                                                                    }}
                                                                />
                                                                <div className="font-medium text-gray-600 text-sm flex py-1 mt-4">
                                                                    <input
                                                                        className="mr-1 mt-1"
                                                                        type="checkbox"
                                                                        checked={
                                                                            enablePublicProfile
                                                                        }
                                                                        onChange={() => {
                                                                            setEnablePublicProfile(
                                                                                !enablePublicProfile
                                                                            );
                                                                        }}
                                                                    />
                                                                    <div>
                                                                        I consent that my profile
                                                                        picture can be shown in the
                                                                        leaderboards.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="font-bold font-manrope text-xl mb-3">
                                                    Public Profile
                                                </div>
                                                <div className="flex flex-row">
                                                    <div className="flex flex-col mb-1 w-full md:w-2/5">
                                                        <label
                                                            className="flex flex-row font-manrope text-sm my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            My Public Profile URL
                                                        </label>
                                                        <div
                                                            className={`pl-4 flex items-center h-12 inputFieldThickness`}
                                                            id="tab_1"
                                                        >{`${window.location.origin}/public-profile/${studentData?.id}`}</div>
                                                    </div>
                                                    <div className="flex flex-col mb-1 ml-8 w-full md:w-2/5">
                                                        <div className="font-medium text-gray-600 text-sm align-top items-start flex py-2 mt-5">
                                                            <input
                                                                className="mr-1 mt-1"
                                                                id="public_profile_consent"
                                                                type="checkbox"
                                                                checked={isPublic}
                                                                onChange={() => {
                                                                    setIsPublic(!isPublic);
                                                                }}
                                                            />
                                                            <label
                                                                className="cursor-pointer"
                                                                htmlFor="public_profile_consent"
                                                            >
                                                                Keep my profile publicly linked and
                                                                accessible through the BRIDGE
                                                                website leaderboard and via QR code
                                                                on the certificates.
                                                                {isPublic && (
                                                                    <span className=" block font-bold text-gray-700">
                                                                        Your personal information
                                                                        and BRIDGE progress
                                                                        information will now be
                                                                        shared publicly.
                                                                    </span>
                                                                )}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-row">
                                                    <div className="flex flex-col my-1 w-full md:w-2/5">
                                                        <label
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            First Name
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </label>
                                                        <input
                                                            className={`text-sm pl-2 py-1 h-12 ${
                                                                isErrorFirstName
                                                                    ? 'inputFieldErrorThickness'
                                                                    : 'inputFieldThickness'
                                                            }`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={firstName}
                                                            placeholder="Enter your first name"
                                                            autoComplete="off"
                                                            onChange={e => {
                                                                setFirstName(e.target.value);
                                                                if (e.target.value.length !== 0) {
                                                                    setIsErrorFirstName(false);
                                                                }
                                                            }}
                                                        />
                                                        {isErrorFirstName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                First Name is mandatory
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                                        <label
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Last Name
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </label>
                                                        <input
                                                            className={`text-sm pl-2 py-1 h-12 ${
                                                                isErrorLastName
                                                                    ? 'inputFieldErrorThickness'
                                                                    : 'inputFieldThickness'
                                                            }`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={lastName}
                                                            placeholder="Enter your last name"
                                                            autoComplete="off"
                                                            onChange={e => {
                                                                setLastName(e.target.value);
                                                                if (e.target.value) {
                                                                    setIsErrorLastName(false);
                                                                }
                                                            }}
                                                        />
                                                        {isErrorLastName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Last Name is mandatory
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row mt-2">
                                                    <div className="flex flex-col my-1 w-full md:w-2/5">
                                                        <label
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Date of Birth
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </label>
                                                        <div className=" leading-normal">
                                                            <label
                                                                htmlFor="set_date"
                                                                className="inputFieldThickness rounded h-12 flex flex-row w-full justify-between"
                                                            >
                                                                <form autoComplete="off">
                                                                    <DatePicker
                                                                        required
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        yearDropdownItemNumber={112}
                                                                        scrollableYearDropdown
                                                                        maxDate={new Date()}
                                                                        id="set_date"
                                                                        className="text-sm p-1 h-7 ml-2 mt-2"
                                                                        selected={dateOfBirth}
                                                                        onChange={date => {
                                                                            if (date != null) {
                                                                                setDateOfBirth(
                                                                                    date
                                                                                );
                                                                            }
                                                                            if (isErrorDOB) {
                                                                                setIsErrorDOB(
                                                                                    false
                                                                                );
                                                                            }
                                                                        }}
                                                                        dateFormat="do MMMM yyyy"
                                                                        placeholderText="Select your date of birth"
                                                                    />
                                                                </form>
                                                                <label
                                                                    className="pr-2"
                                                                    htmlFor="set_date"
                                                                >
                                                                    <HiIcons.HiCalendar className="mr-2 mt-3" />
                                                                </label>
                                                            </label>
                                                            {isErrorDOB ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Select your date of birth
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                                        <label
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Gender
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </label>
                                                        <div
                                                            className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                                isErrorGender
                                                                    ? 'inputFieldErrorThickness'
                                                                    : 'inputFieldThickness'
                                                            } `}
                                                        >
                                                            <div
                                                                className="flex justify-between w-full"
                                                                onClick={() =>
                                                                    setGenderDropdownVisible(true)
                                                                }
                                                            >
                                                                <div
                                                                    className={`text-sm pl-2 ${
                                                                        trackFilterGenderAction !==
                                                                        'Select your gender'
                                                                            ? 'text-gray-700'
                                                                            : 'text-gray-400'
                                                                    }`}
                                                                >
                                                                    {trackFilterGenderAction}
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex flex-row">
                                                                        <div className="flex flex-col">
                                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {genderDropdownVisible ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setGenderDropdownVisible(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {TrackGenderFilter.map(
                                                                        action => (
                                                                            <div
                                                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                                                key={action.id}
                                                                                onClick={() => {
                                                                                    setIsErrorGender(
                                                                                        false
                                                                                    );
                                                                                    setTrackFilterGenderAction(
                                                                                        action.val
                                                                                    );
                                                                                    setGenderDropdownVisible(
                                                                                        false
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {action.val}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                        {isErrorGender ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Select a gender
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row">
                                                    <div className="flex flex-col my-1 w-full md:w-2/5">
                                                        <label
                                                            className="flex my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            NIC / Passport Number
                                                        </label>
                                                        <input
                                                            className={`text-sm rounded pl-2 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={nicNumber}
                                                            placeholder="Enter your NIC / Passport number"
                                                            autoComplete="off"
                                                            onChange={e => {
                                                                setNICNumber(e.target.value);
                                                                if (isErrorNIC) {
                                                                    setIsErrorNIC(false);
                                                                }
                                                            }}
                                                        />
                                                        {isErrorNIC ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Invalid NIC
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="flex flex-col my-1 ml-8 w-full md:w-2/5">
                                                        <label
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_mobile"
                                                        >
                                                            Mobile Number
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </label>
                                                        <div>
                                                            <label
                                                                htmlFor="set_mobile"
                                                                className={`rounded h-12 flex flex-row w-full justify-between ${
                                                                    isErrorMobileNo
                                                                        ? 'inputFieldErrorThickness'
                                                                        : 'inputFieldThickness'
                                                                }  ${
                                                                    studentData?.contact
                                                                        ?.mobileNo !== null &&
                                                                    studentData?.contact
                                                                        ?.mobileNo !== undefined &&
                                                                    'pointer-events-none opacity-50'
                                                                }`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '45px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    country="lk"
                                                                    specialLabel={''}
                                                                    value={mobileNumber}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                                                    onChange={(e, country: any) => {
                                                                        if (!isNaN(Number(e))) {
                                                                            const Index =
                                                                                country.dialCode
                                                                                    .length;

                                                                            if (e[Index] === '0') {
                                                                                const modifiedNumber =
                                                                                    e.slice(
                                                                                        0,
                                                                                        Index
                                                                                    ) +
                                                                                    e.slice(
                                                                                        Index + 1
                                                                                    );

                                                                                setMobileNumber(
                                                                                    modifiedNumber
                                                                                );
                                                                            } else {
                                                                                setMobileNumber(e);
                                                                            }
                                                                            setIsErrorMobileNo(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                {verifyPhoneNumberSuccess ||
                                                                (studentData?.contact?.mobileNo !==
                                                                    null &&
                                                                    studentData?.contact
                                                                        ?.mobileNo !==
                                                                        undefined) ? (
                                                                    <div className="mr-2 mt-0.5">
                                                                        <Verified />
                                                                    </div>
                                                                ) : verifyPhoneNumber ? (
                                                                    <div
                                                                        onClick={sendOTP}
                                                                        className="flex items-center mr-2 my-2 bg-green-450 text-white text-xs rounded-full px-3  w-max cursor-pointer"
                                                                    >
                                                                        Verify
                                                                    </div>
                                                                ) : null}
                                                            </label>
                                                        </div>

                                                        {isErrorMobileNo ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {sendMobileVerificationFailed ? (
                                                                    sendMobileVerificationFailed.includes(
                                                                        'already exists'
                                                                    ) ? (
                                                                        <>
                                                                            Your mobile number
                                                                            already exists. Please{' '}
                                                                            <span
                                                                                className="text-blue-500 cursor-pointer underline"
                                                                                onClick={() => {
                                                                                    dispatch(
                                                                                        logoutWeb()
                                                                                    );
                                                                                    history.push({
                                                                                        pathname:
                                                                                            '/signin',
                                                                                        state: {
                                                                                            tab: 'byPhone',
                                                                                        },
                                                                                    });
                                                                                }}
                                                                            >
                                                                                sign in
                                                                            </span>{' '}
                                                                            with the mobile number.
                                                                        </>
                                                                    ) : (
                                                                        sendMobileVerificationFailed
                                                                    )
                                                                ) : (
                                                                    'Enter valid Mobile number'
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1 w-full md:w-2/5">
                                                    <div className="flex flex-row">
                                                        <label
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Email
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </label>
                                                        <HiIcons.HiQuestionMarkCircle
                                                            onClick={() => {
                                                                setEmailHelpText(true);
                                                            }}
                                                            className="ml-2 mt-1 cursor-pointer"
                                                        />
                                                        {emailHelpText ? (
                                                            <div
                                                                onMouseOverCapture={() => {
                                                                    setEmailHelpText(true);
                                                                }}
                                                                onMouseLeave={() =>
                                                                    setEmailHelpText(false)
                                                                }
                                                                className="bg-white border-2 rounded-md absolute z-10 px-4 py-2 mt-7"
                                                                style={{
                                                                    width: '375px',
                                                                }}
                                                            >
                                                                If you don’t have an email already,{' '}
                                                                <span className="border-b-2 border-gray-400 cursor-pointer">
                                                                    <a
                                                                        target="_blank"
                                                                        href={emailCreationURL}
                                                                        rel="noreferrer"
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                </span>{' '}
                                                                to learn more.
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <input
                                                        className={`text-sm pl-2 py-1 h-12 ${
                                                            isErrorEmail
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        } ${
                                                            studentData?.emailId !== null &&
                                                            studentData?.emailId !== undefined &&
                                                            'pointer-events-none opacity-50'
                                                        }`}
                                                        type="email"
                                                        id="tab_1"
                                                        value={values.email}
                                                        placeholder="Enter your email address"
                                                        autoComplete="off"
                                                        onChange={e => {
                                                            setFieldValue('email', e.target.value);
                                                            setEmail(e.target.value);
                                                            if (e.target.value) {
                                                                setIsErrorEmail(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.email ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                    {isErrorEmail ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {emailVerificationSuccessMessage}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <p className="font-bold font-manrope text-lg mt-4 mb-2">
                                                    Address Information
                                                </p>
                                                <div className="flex flex-col my-1">
                                                    <div className="flex flex-row my-1">
                                                        Home town (District)
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  ${
                                                            isErrorHomeTown
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        }`}
                                                    >
                                                        <div
                                                            className="flex justify-between w-full"
                                                            onClick={() =>
                                                                setHometownDropdownVisible(true)
                                                            }
                                                        >
                                                            <div className="text-gray-700 font-medium pl-2">
                                                                {trackFilterAddressAction}
                                                            </div>
                                                            <div className="">
                                                                <div className="flex flex-row">
                                                                    {trackFilterAddressAction !==
                                                                    'Select your home town' ? (
                                                                        <HiIcons.HiOutlineX
                                                                            className="mr-2 mt-1"
                                                                            onClick={() =>
                                                                                setTrackFilterAddressAction(
                                                                                    'Select your home town'
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {hometownDropdownVisible ? (
                                                            <ul
                                                                id="hometwonId"
                                                                className="max-h-40 overflow-y-auto absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                role="menu"
                                                                onMouseLeave={() =>
                                                                    setHometownDropdownVisible(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                {TrackAddressFilter.map(action => (
                                                                    <div
                                                                        className="p-2 pl-2 hover:bg-gray-200 "
                                                                        key={action.id}
                                                                        onClick={() => {
                                                                            setIsErrorHomeTown(
                                                                                false
                                                                            );
                                                                            setTrackFilterAddressAction(
                                                                                action.val
                                                                            );
                                                                            setHometownDropdownVisible(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        {action.val}
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                    {isErrorHomeTown ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Home town is mandatory
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div
                                                    style={{ marginTop: `${belowHometown}px` }}
                                                    className="flex flex-col  w-full md:w-2/5"
                                                >
                                                    <label
                                                        className="flex flex-row "
                                                        htmlFor="tab_1"
                                                    >
                                                        Postal Address
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </label>
                                                    <input
                                                        className={`text-sm pl-2 py-1 h-12 ${
                                                            isErrorAddress
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        }`}
                                                        type="text"
                                                        id="tab_1"
                                                        value={address}
                                                        placeholder="Enter the postal address"
                                                        autoComplete="off"
                                                        onChange={e => {
                                                            setAddress(e.target.value);
                                                            if (e.target.value) {
                                                                setIsErrorAddress(false);
                                                            }
                                                        }}
                                                    />
                                                    {isErrorAddress ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Postal address is mandatory
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="flex justify-end mr-20 mb-4">
                                                    <button
                                                        className="bg-purple-950 text-white rounded w-40 h-10 mt-20"
                                                        onClick={() => {
                                                            PersonalDetailsCheck();
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                        ) : null}
                                        {onBoardFlow === 'education' ? (
                                            <>
                                                <div className="font-bold font-manrope text-2xl-2 mt-10">
                                                    Educational Details
                                                </div>
                                                <div className="font-bold font-manrope text-lg mt-4">
                                                    Select your Secondary School education level
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </div>
                                                <div className="mt-5">
                                                    <div className="mb-2">
                                                        <input
                                                            id="1"
                                                            className="newone"
                                                            type="radio"
                                                            value="I have not done O/Ls yet"
                                                            checked={
                                                                secondaryEd ===
                                                                'I have not done O/Ls yet'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor="1">
                                                            I have not done O/Ls yet
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="2"
                                                            className="newone"
                                                            type="radio"
                                                            value="I have completed O/Ls but not A/Ls"
                                                            checked={
                                                                secondaryEd ===
                                                                `I have completed O/Ls but not A/Ls`
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor="2">
                                                            I have completed O/Ls but not A/Ls
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="3"
                                                            className="newone"
                                                            type="radio"
                                                            value="I have completed A/Ls"
                                                            checked={
                                                                secondaryEd ===
                                                                'I have completed A/Ls'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor="3">
                                                            I have completed A/Ls
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="4"
                                                            className="newone"
                                                            type="radio"
                                                            value="I am doing higher studies but not an undergraduate"
                                                            checked={
                                                                secondaryEd ===
                                                                'I am doing higher studies but not an undergraduate'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor="4">
                                                            I am doing higher studies but not an
                                                            undergraduate
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="5"
                                                            className="newone"
                                                            type="radio"
                                                            value="I am an undergraduate"
                                                            checked={
                                                                secondaryEd ===
                                                                'I am an undergraduate'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3" htmlFor="5">
                                                            I am an undergraduate
                                                        </label>
                                                    </div>
                                                    {isErrorEducationStatus ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your secondary education level
                                                        </div>
                                                    ) : null}
                                                </div>

                                                {secondaryEd !== 'I have not done O/Ls yet' ? (
                                                    <>
                                                        {secondaryEd !==
                                                        'I have completed O/Ls but not A/Ls' ? (
                                                            <>
                                                                <div>
                                                                    <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                                        Stream of study in Advanced
                                                                        Level
                                                                    </div>

                                                                    <div className="flex flex-col mt-3 mb-1 w-full md:w-3/4">
                                                                        <label
                                                                            className="flex flex-row my-1"
                                                                            htmlFor="tab_1"
                                                                        >
                                                                            Advanced Level Stream
                                                                            <span className="text-red-600">
                                                                                {' '}
                                                                                *
                                                                            </span>
                                                                        </label>

                                                                        <div
                                                                            className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                                                isErrorStream
                                                                                    ? 'inputFieldErrorThickness'
                                                                                    : 'inputFieldThickness'
                                                                            } `}
                                                                        >
                                                                            <div
                                                                                className="flex justify-between w-full"
                                                                                onClick={() =>
                                                                                    setStreamDropdownVisible(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={`text-sm pl-2 ${
                                                                                        AlStreamFilterAction !==
                                                                                        'Select your gender'
                                                                                            ? 'text-gray-700'
                                                                                            : 'text-gray-400'
                                                                                    }`}
                                                                                >
                                                                                    {
                                                                                        AlStreamFilterAction
                                                                                    }
                                                                                </div>
                                                                                <div className="">
                                                                                    <div className="flex flex-row">
                                                                                        <div className="flex flex-col">
                                                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {streamDropdownVisible ? (
                                                                                <ul
                                                                                    className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                    role="menu"
                                                                                    onMouseLeave={() =>
                                                                                        setStreamDropdownVisible(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {AlStreamFilter.map(
                                                                                        action => (
                                                                                            <div
                                                                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                key={
                                                                                                    action.id
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    setIsErrorStream(
                                                                                                        false
                                                                                                    );
                                                                                                    setAlStreamFilterAction(
                                                                                                        action.val
                                                                                                    );
                                                                                                    setStream(
                                                                                                        action.val
                                                                                                    );
                                                                                                    setStreamDropdownVisible(
                                                                                                        false
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    action.val
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    {isErrorStream && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            Select your A/L Stream
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : null}

                                                        <div>
                                                            <div className="font-bold font-manrope text-lg mt-8">
                                                                Are you currently pursuing Higher
                                                                Education?
                                                                <span className="text-red-600">
                                                                    &nbsp;*
                                                                </span>
                                                            </div>
                                                            <div className="mt-5">
                                                                <div className="mb-2">
                                                                    <input
                                                                        id="higherEd1"
                                                                        className="newone"
                                                                        type="radio"
                                                                        value="Yes"
                                                                        checked={
                                                                            isPursuingHigherEducation ===
                                                                            'Yes'
                                                                        }
                                                                        onChange={e => {
                                                                            setIsPursuingHigherEducation(
                                                                                e.target.value
                                                                            );
                                                                            setPursuingHigherStudies(
                                                                                1
                                                                            );
                                                                            setIsErrorPursueHigherEducation(
                                                                                false
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="ml-3"
                                                                        htmlFor="higherEd1"
                                                                    >
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <input
                                                                        id="higherEd2"
                                                                        className="newone"
                                                                        type="radio"
                                                                        value="No"
                                                                        checked={
                                                                            isPursuingHigherEducation ===
                                                                            'No'
                                                                        }
                                                                        onChange={e => {
                                                                            setIsPursuingHigherEducation(
                                                                                e.target.value
                                                                            );
                                                                            setPursuingHigherStudies(
                                                                                0
                                                                            );
                                                                            setIsErrorPursueHigherEducation(
                                                                                false
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="ml-3"
                                                                        htmlFor="higherEd2"
                                                                    >
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {isErrorPursueHigherEducation ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Select your answer
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {isPursuingHigherEducation === 'Yes' ? (
                                                            <>
                                                                <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                                    Institute Details
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <div className="flex flex-row mb-1">
                                                                        Institute Name
                                                                        <span className="text-red-600">
                                                                            &nbsp;*
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  inputFieldThickness">
                                                                        <div
                                                                            className="flex justify-between w-full"
                                                                            onClick={() =>
                                                                                setDropdownVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            <div className="text-gray-700 font-medium pl-2 ">
                                                                                {trackFilterAction}
                                                                            </div>
                                                                            <div className="">
                                                                                <div className="flex flex-row">
                                                                                    {trackFilterAction !==
                                                                                    'Select your institute' ? (
                                                                                        <HiIcons.HiOutlineX
                                                                                            className="mr-2 mt-1"
                                                                                            onClick={() => {
                                                                                                setTrackFilterAction(
                                                                                                    'Select a institute'
                                                                                                );
                                                                                                setInstitute(
                                                                                                    null
                                                                                                );
                                                                                                setInstituteID(
                                                                                                    null
                                                                                                );
                                                                                                setcat1FilterAction(
                                                                                                    'Select'
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    ) : null}
                                                                                    <div className="flex flex-col">
                                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {dropdownVisible ? (
                                                                            <ul
                                                                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-40 overflow-y-scroll"
                                                                                role="menu"
                                                                                onMouseLeave={() =>
                                                                                    setDropdownVisible(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            >
                                                                                {instituteData?.map(
                                                                                    action => (
                                                                                        <div
                                                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                                                            key={
                                                                                                action.id
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setIsErrorInstituteName(
                                                                                                    false
                                                                                                );
                                                                                                setTrackFilterAction(
                                                                                                    action.instName
                                                                                                );
                                                                                                setDropdownVisible(
                                                                                                    false
                                                                                                );
                                                                                                setInstitute(
                                                                                                    action.instName
                                                                                                );
                                                                                                setInstituteID(
                                                                                                    action.id
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                action.instName
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        ) : null}
                                                                    </div>
                                                                    {isErrorInstituteName && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            Select your Institute
                                                                            Name
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                {institue !== null
                                                                    ? selectedInstitue?.map(
                                                                          data => (
                                                                              <div key={data?.id}>
                                                                                  {isCat1 ? (
                                                                                      <div className="flex flex-col mt-1">
                                                                                          <div className="flex flex-row mb-1">
                                                                                              {
                                                                                                  data
                                                                                                      ?.instCategory1
                                                                                                      ?.name
                                                                                              }
                                                                                          </div>
                                                                                          <div
                                                                                              className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900 inputFieldThickness `}
                                                                                          >
                                                                                              <div
                                                                                                  className="flex justify-between w-full"
                                                                                                  onClick={() =>
                                                                                                      setInstitueCat1DropDownVisible(
                                                                                                          true
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <div className="text-gray-700 font-medium pl-2">
                                                                                                      {
                                                                                                          cat1FilterAction
                                                                                                      }
                                                                                                  </div>
                                                                                                  <div className="">
                                                                                                      <div className="flex flex-row">
                                                                                                          {cat1FilterAction !==
                                                                                                          'Select' ? (
                                                                                                              <HiIcons.HiOutlineX
                                                                                                                  className="mr-2 mt-1"
                                                                                                                  onClick={() =>
                                                                                                                      setcat1FilterAction(
                                                                                                                          'Select'
                                                                                                                      )
                                                                                                                  }
                                                                                                              />
                                                                                                          ) : null}
                                                                                                          <div className="flex flex-col">
                                                                                                              <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                              <HiIcons.HiChevronDown className="mr-2" />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                              {institueCat1DropDownVisible ? (
                                                                                                  <ul
                                                                                                      className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                                      role="menu"
                                                                                                      onMouseLeave={() =>
                                                                                                          setInstitueCat1DropDownVisible(
                                                                                                              false
                                                                                                          )
                                                                                                      }
                                                                                                  >
                                                                                                      {data?.instCategory1?.options.map(
                                                                                                          action => (
                                                                                                              <div
                                                                                                                  className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                                  key={
                                                                                                                      action
                                                                                                                  }
                                                                                                                  onClick={() => {
                                                                                                                      setcat1FilterAction(
                                                                                                                          action
                                                                                                                      );
                                                                                                                      setInstitueCat1DropDownVisible(
                                                                                                                          false
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      action
                                                                                                                  }
                                                                                                              </div>
                                                                                                          )
                                                                                                      )}
                                                                                                  </ul>
                                                                                              ) : null}
                                                                                          </div>
                                                                                      </div>
                                                                                  ) : null}

                                                                                  {isCat2 ? (
                                                                                      <div className="flex flex-col mt-1">
                                                                                          <div className="flex flex-row mb-1">
                                                                                              {
                                                                                                  data
                                                                                                      ?.instCategory2
                                                                                                      ?.name
                                                                                              }
                                                                                          </div>
                                                                                          <div
                                                                                              className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                                          >
                                                                                              <div
                                                                                                  className="flex justify-between w-full"
                                                                                                  onClick={() =>
                                                                                                      setInstitueCat2DropDownVisible(
                                                                                                          true
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <div className="text-gray-700 font-medium pl-2">
                                                                                                      {
                                                                                                          cat2FilterAction
                                                                                                      }
                                                                                                  </div>
                                                                                                  <div className="">
                                                                                                      <div className="flex flex-row">
                                                                                                          {cat2FilterAction !==
                                                                                                          'Select' ? (
                                                                                                              <HiIcons.HiOutlineX
                                                                                                                  className="mr-2 mt-1"
                                                                                                                  onClick={() =>
                                                                                                                      setcat2FilterAction(
                                                                                                                          'Select'
                                                                                                                      )
                                                                                                                  }
                                                                                                              />
                                                                                                          ) : null}
                                                                                                          <div className="flex flex-col">
                                                                                                              <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                              <HiIcons.HiChevronDown className="mr-2" />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                              {institueCat2DropDownVisible ? (
                                                                                                  <ul
                                                                                                      className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                                      role="menu"
                                                                                                      onMouseLeave={() =>
                                                                                                          setInstitueCat2DropDownVisible(
                                                                                                              false
                                                                                                          )
                                                                                                      }
                                                                                                  >
                                                                                                      {data?.instCategory2?.options.map(
                                                                                                          action => (
                                                                                                              <div
                                                                                                                  className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                                  key={
                                                                                                                      action
                                                                                                                  }
                                                                                                                  onClick={() => {
                                                                                                                      setcat2FilterAction(
                                                                                                                          action
                                                                                                                      );
                                                                                                                      setInstitueCat2DropDownVisible(
                                                                                                                          false
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      action
                                                                                                                  }
                                                                                                              </div>
                                                                                                          )
                                                                                                      )}
                                                                                                  </ul>
                                                                                              ) : null}
                                                                                          </div>
                                                                                      </div>
                                                                                  ) : null}

                                                                                  {isCat3 ? (
                                                                                      <div className="flex flex-col mt-1">
                                                                                          <div className="flex flex-row mb-1">
                                                                                              {
                                                                                                  data
                                                                                                      ?.instCategory3
                                                                                                      ?.name
                                                                                              }
                                                                                          </div>
                                                                                          <div
                                                                                              className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                                          >
                                                                                              <div
                                                                                                  className="flex justify-between w-full"
                                                                                                  onClick={() =>
                                                                                                      setInstitueCat3DropDownVisible(
                                                                                                          true
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <div className="text-gray-700 font-medium pl-2">
                                                                                                      {
                                                                                                          cat3FilterAction
                                                                                                      }
                                                                                                  </div>
                                                                                                  <div className="">
                                                                                                      <div className="flex flex-row">
                                                                                                          {cat3FilterAction !==
                                                                                                          'Select' ? (
                                                                                                              <HiIcons.HiOutlineX
                                                                                                                  className="mr-2 mt-1"
                                                                                                                  onClick={() =>
                                                                                                                      setcat3FilterAction(
                                                                                                                          'Select'
                                                                                                                      )
                                                                                                                  }
                                                                                                              />
                                                                                                          ) : null}
                                                                                                          <div className="flex flex-col">
                                                                                                              <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                              <HiIcons.HiChevronDown className="mr-2" />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                              {institueCat3DropDownVisible ? (
                                                                                                  <ul
                                                                                                      className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                                      role="menu"
                                                                                                      onMouseLeave={() =>
                                                                                                          setInstitueCat3DropDownVisible(
                                                                                                              false
                                                                                                          )
                                                                                                      }
                                                                                                  >
                                                                                                      {data?.instCategory3?.options.map(
                                                                                                          action => (
                                                                                                              <div
                                                                                                                  className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                                  key={
                                                                                                                      action
                                                                                                                  }
                                                                                                                  onClick={() => {
                                                                                                                      setcat3FilterAction(
                                                                                                                          action
                                                                                                                      );
                                                                                                                      setInstitueCat3DropDownVisible(
                                                                                                                          false
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      action
                                                                                                                  }
                                                                                                              </div>
                                                                                                          )
                                                                                                      )}
                                                                                                  </ul>
                                                                                              ) : null}
                                                                                          </div>
                                                                                      </div>
                                                                                  ) : null}
                                                                              </div>
                                                                          )
                                                                      )
                                                                    : null}

                                                                <div className="flex flex-col mt-5 mb-1 w-full md:w-2/4">
                                                                    <label
                                                                        className="flex flex-row my-1"
                                                                        htmlFor="tab_1"
                                                                    >
                                                                        Student Registration
                                                                        Number/Student ID given by
                                                                        your Institute
                                                                        <span className="text-red-600">
                                                                            &nbsp;*
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        className="text-sm pl-2 py-1 h-12 inputFieldThickness"
                                                                        type="text"
                                                                        id="tab_1"
                                                                        value={batchNumber}
                                                                        placeholder="Enter your Student Registration Number/Student ID"
                                                                        onChange={e => {
                                                                            setIsErrorInstituteRegId(
                                                                                false
                                                                            );
                                                                            setBatchNumber(
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                    {isErrorInstituteRegId && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            Enter your Student
                                                                            Registration Number
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                                <div className="flex flex-col w-full md:w-2/4">
                                                    <div className="font-bold font-manrope text-lg mt-7">
                                                        BRIDGE invitation code given by your
                                                        Institute (optional)
                                                    </div>
                                                    <input
                                                        className={`inputFieldThickness pl-2 py-1 h-12 border-gray-300 mt-4`}
                                                        type="text"
                                                        id="tab_1"
                                                        value={bridgeNumber}
                                                        placeholder="Enter the BRIDGE invitation code given"
                                                        onChange={e => {
                                                            setBridgeNumber(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="font-bold font-manrope text-lg mt-8">
                                                        I joined Future Careers BRIDGE program
                                                        because,
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="mb-2">
                                                            <input
                                                                id="futurecar1"
                                                                className="newone"
                                                                type="radio"
                                                                value="Only interested in studying at this time"
                                                                checked={
                                                                    joinedFutureCareerBridgeReason ===
                                                                    'Only interested in studying at this time'
                                                                }
                                                                onChange={e => {
                                                                    setJoinedFutureCareerBridgeReason(
                                                                        e.target.value
                                                                    );
                                                                    setIsErrorrReasonForFCB(false);
                                                                    setAcademicOnly(true);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="futurecar1"
                                                            >
                                                                Only interested in studying at this
                                                                time(Your profile will not be
                                                                visible to companies for
                                                                recruitment)
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="futurecar2"
                                                                className="newone"
                                                                type="radio"
                                                                value="Looking for an industry internship"
                                                                checked={
                                                                    joinedFutureCareerBridgeReason ===
                                                                    'Looking for an industry internship'
                                                                }
                                                                onChange={e => {
                                                                    setJoinedFutureCareerBridgeReason(
                                                                        e.target.value
                                                                    );
                                                                    setIsErrorrReasonForFCB(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="futurecar2"
                                                            >
                                                                Looking for an industry internship
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="futurecar3"
                                                                className="newone"
                                                                type="radio"
                                                                value="Already in employment but looking for new Tech career"
                                                                checked={
                                                                    joinedFutureCareerBridgeReason ===
                                                                    'Already in employment but looking for new Tech career'
                                                                }
                                                                onChange={e => {
                                                                    setJoinedFutureCareerBridgeReason(
                                                                        e.target.value
                                                                    );
                                                                    setIsErrorrReasonForFCB(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="futurecar3"
                                                            >
                                                                Already in employment but looking
                                                                for new Tech career
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isErrorrReasonForFCB ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your answer
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div>
                                                    <div className="font-bold font-manrope text-lg mt-8">
                                                        Are you presently employed (full time or
                                                        part time)?
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="mb-2">
                                                            <input
                                                                id="employed1"
                                                                className="newone"
                                                                type="radio"
                                                                value="No"
                                                                checked={isEmployed === 'No'}
                                                                onChange={e => {
                                                                    setIsEmployed(e.target.value);
                                                                    setIsErrorEmployed(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="employed1"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="employed2"
                                                                className="newone"
                                                                type="radio"
                                                                value="Yes, at a company"
                                                                checked={
                                                                    isEmployed ===
                                                                    'Yes, at a company'
                                                                }
                                                                onChange={e => {
                                                                    setIsEmployed(e.target.value);
                                                                    setIsErrorEmployed(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="employed2"
                                                            >
                                                                Yes, at a company
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="employed3"
                                                                className="newone"
                                                                type="radio"
                                                                value="Yes, self-employed"
                                                                checked={
                                                                    isEmployed ===
                                                                    'Yes, self-employed'
                                                                }
                                                                onChange={e => {
                                                                    setIsEmployed(e.target.value);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3"
                                                                htmlFor="employed3"
                                                            >
                                                                Yes, self-employed
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isErrorEmployed ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your answer
                                                        </div>
                                                    ) : null}
                                                </div>

                                                {isEmployed === 'Yes, at a company' ? (
                                                    <>
                                                        <div className="font-bold font-manrope text-lg mt-8 mb-5">
                                                            Company Details
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <div className="flex flex-row mb-1">
                                                                Company name
                                                                <span className="text-red-600">
                                                                    &nbsp;*
                                                                </span>
                                                            </div>
                                                            <div className="flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  inputFieldThickness">
                                                                <div
                                                                    className="flex justify-between w-full "
                                                                    onClick={() =>
                                                                        setCompanyDropdownVisible(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="text-gray-700 font-medium pl-2">
                                                                        {companyFilterAction}
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="flex flex-row">
                                                                            {companyFilterAction !==
                                                                            'Select your company' ? (
                                                                                <HiIcons.HiOutlineX
                                                                                    className="mr-2 mt-1"
                                                                                    onClick={() => {
                                                                                        setCompanyFilterAction(
                                                                                            'Select your company'
                                                                                        );
                                                                                        setCompany(
                                                                                            null
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            ) : null}
                                                                            <div className="flex flex-col">
                                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {companyDropdownVisible ? (
                                                                    <ul
                                                                        className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-50 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-40 overflow-y-scroll"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setCompanyDropdownVisible(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        {companyData?.map(
                                                                            action => (
                                                                                <div
                                                                                    className="p-2 pl-2 hover:bg-gray-200 "
                                                                                    key={action.id}
                                                                                    onClick={() => {
                                                                                        setCompanyFilterAction(
                                                                                            action.comName
                                                                                        );
                                                                                        setCompanyDropdownVisible(
                                                                                            false
                                                                                        );
                                                                                        setCompany(
                                                                                            action.comName
                                                                                        );
                                                                                        setIsErrorCompany(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {action.comName}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        <div
                                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                                            onClick={() => {
                                                                                setCompany(null);
                                                                                setCompanyFilterAction(
                                                                                    'Others'
                                                                                );
                                                                                setCompanyDropdownVisible(
                                                                                    false
                                                                                );
                                                                                setIsErrorCompany(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            Others
                                                                        </div>
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                            {isErrorCompany ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Select your company
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {companyFilterAction === 'Others' ? (
                                                            <div>
                                                                <div className="flex flex-col mt-5 mb-1 w-full md:w-3/4">
                                                                    <label
                                                                        className="flex flex-row my-1"
                                                                        htmlFor="tab_1"
                                                                    >
                                                                        Enter your Company name
                                                                        <span className="text-red-600">
                                                                            &nbsp;*
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        className={`text-sm pl-2 py-1 h-12 ${
                                                                            isErrorFirstName
                                                                                ? 'inputFieldErrorThickness'
                                                                                : 'inputFieldThickness'
                                                                        }`}
                                                                        type="text"
                                                                        id="tab_1"
                                                                        value={otherCompany}
                                                                        placeholder="Company name"
                                                                        onChange={e => {
                                                                            setCompany(
                                                                                e.target.value
                                                                            );
                                                                            setOtherCompany(
                                                                                e.target.value
                                                                            );
                                                                            if (
                                                                                e.target.value
                                                                                    .length > 0
                                                                            ) {
                                                                                setIsErrorOtherCompany(
                                                                                    false
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {isErrorOtherCompany ? (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        Company name is mandatory
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : null}
                                                    </>
                                                ) : null}

                                                <div className="flex flex-row justify-between mb-4 pb-6">
                                                    <div className="">
                                                        <button
                                                            className="border-2 border-purple-950 bg-white text-purple-950 rounded w-40 h-10 mt-20"
                                                            onClick={() => {
                                                                setOnboardFlow('personal-details');
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            Back
                                                        </button>
                                                    </div>
                                                    <div className="flex justify-end mr-20">
                                                        <button
                                                            className="bg-purple-950 text-white rounded w-40 h-10 mt-20"
                                                            onClick={() => {
                                                                educationDetailsChecked();
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}

                                        {onBoardFlow === 'field-subscribe' ? (
                                            <>
                                                <div className="font-bold font-manrope text-2xl-2 mt-10">
                                                    Interests and Skills
                                                </div>
                                                <p className="mt-3">
                                                    Tell us your areas of interests and skills, so
                                                    that we can recommend you a career path.
                                                </p>
                                                <p className="font-bold font-manrope text-lg mt-3">
                                                    Please select at least 3 from below.
                                                </p>
                                                <div className="flex flex-col mt-2">
                                                    <div className="flex flex-wrap  mr-20">
                                                        {interestTag?.map(action => (
                                                            <div
                                                                style={{
                                                                    maxHeight: '10px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                key={action.id}
                                                                className={`rounded-full border-2 my-1 mr-4 cursor-pointer ${
                                                                    selectedTags?.includes(
                                                                        action.tagName
                                                                    )
                                                                        ? ' text-white bg-purple-950 border-purple-950'
                                                                        : 'text-purple-950 border-purple-950 font-medium'
                                                                }  py-3 px-2  hover:bg-purple-950 hover:text-white`}
                                                                onClick={() => {
                                                                    if (
                                                                        !selectedTags?.includes(
                                                                            action.tagName
                                                                        )
                                                                    ) {
                                                                        setInterestedTags([
                                                                            ...interestedTags,
                                                                            action,
                                                                        ]);
                                                                    }
                                                                }}
                                                            >
                                                                {action.tagName}
                                                                {selectedTags?.includes(
                                                                    action.tagName
                                                                ) ? (
                                                                    <HiIcons.HiOutlineX
                                                                        style={{ color: 'white' }}
                                                                        className="ml-2"
                                                                        onClick={() =>
                                                                            setInterestedTags(
                                                                                interestedTags?.filter(
                                                                                    item =>
                                                                                        item.tagName !==
                                                                                        action.tagName
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {isErrorInterestedField ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select at least 3 from above to complete
                                                            the registration process
                                                        </div>
                                                    ) : null}

                                                    <div className="flex flex-row justify-between mb-80 pb-6 mt-6">
                                                        <div className="">
                                                            <button
                                                                className="border-2 border-purple-950 bg-white text-purple-950 rounded w-40 h-10 "
                                                                onClick={() => {
                                                                    setOnboardFlow('education');
                                                                }}
                                                            >
                                                                Back
                                                            </button>
                                                        </div>
                                                        <div className="flex justify-end mr-20">
                                                            <button
                                                                type="submit"
                                                                className="bg-purple-950 text-white rounded w-40 h-10"
                                                                onClick={() => {
                                                                    handleSubmit();
                                                                }}
                                                            >
                                                                Complete
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <Modal isOpen={showModal} style={customStyles}>
                                                        <>
                                                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                                                <div className="relative w-1/3 my-6 mx-auto">
                                                                    {/* content */}
                                                                    {isEditLoading ? (
                                                                        <Loader />
                                                                    ) : StudentUpdateSuccessMessage ? (
                                                                        <div
                                                                            style={{
                                                                                maxHeight: '270px',
                                                                            }}
                                                                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    top: '-90px',
                                                                                }}
                                                                                className="flex flex-col -bottom-2"
                                                                            >
                                                                                <>
                                                                                    <span className="successTick self-center"></span>
                                                                                    <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                                                        Congratulations!
                                                                                    </h1>
                                                                                    <p className="text-center my-2 font-semibold">
                                                                                        We have
                                                                                        created your
                                                                                        BRIDGE
                                                                                        account now.
                                                                                    </p>
                                                                                    <div className="flex justify-center mt-3">
                                                                                        <button
                                                                                            className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                                                            onClick={() => {
                                                                                                window.location.href = `/dashBoard/learn`;
                                                                                            }}
                                                                                        >
                                                                                            Continue
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                maxHeight: '270px',
                                                                            }}
                                                                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    top: '-90px',
                                                                                }}
                                                                                className="flex flex-col -bottom-2"
                                                                            >
                                                                                <>
                                                                                    <span className="successTick self-center"></span>
                                                                                    <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                                                        Sorry!
                                                                                    </h1>
                                                                                    <p className="text-center my-2 font-semibold">
                                                                                        Something
                                                                                        went
                                                                                        wrong...
                                                                                    </p>
                                                                                    <div className="flex justify-center mt-3">
                                                                                        <button
                                                                                            className=" bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                                                            onClick={() => {
                                                                                                window.location.href = `/onboard`;
                                                                                            }}
                                                                                        >
                                                                                            Try
                                                                                            again
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </Modal>
                                                </div>
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-col">
                        <div className="h-44 border-b-2">
                            <div className="mt-6 font-bold font-manrope text-lg text-center">
                                Complete Your account
                            </div>
                            <div className="horizontal-line mt-12 relative">
                                <div className="absolute w-full -top-5">
                                    <ul className="horizontal-line-content">
                                        <li>
                                            <div className="flex flex-col justify-center items-center">
                                                <div>
                                                    {onBoardFlow === 'personal-details' ? (
                                                        <CheckedCircle />
                                                    ) : (
                                                        <TickCircle />
                                                    )}
                                                </div>
                                                <div className=" text-purple-950">
                                                    Personal Details
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex flex-col justify-center items-center">
                                                <div>
                                                    {onBoardFlow === 'education' ? (
                                                        <CheckedCircle />
                                                    ) : onBoardFlow === 'field-subscribe' ? (
                                                        <TickCircle />
                                                    ) : (
                                                        <LockCircle />
                                                    )}
                                                </div>
                                                <div className=" text-purple-950">
                                                    Educational Details
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex flex-col justify-center items-center">
                                                <div>
                                                    {onBoardFlow === 'field-subscribe' ? (
                                                        <CheckedCircle />
                                                    ) : (
                                                        <LockCircle />
                                                    )}
                                                </div>
                                                <div className=" text-purple-950">
                                                    Interests and Skills
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <Formik
                                enableReinitialize
                                validationSchema={validationSchemaOnboard}
                                initialValues={{ email: studentData?.emailId, password: '' }}
                                onSubmit={async values => {
                                    PersonalDetailsCheckFinal(values);
                                }}
                            >
                                {({ handleSubmit, setFieldValue, values, errors }) => (
                                    <>
                                        {onBoardFlow === 'personal-details' ? (
                                            <div className="px-4">
                                                <MobileNotVerifiedModal
                                                    showModal={mobileNotVerifiedShowModal}
                                                    setShowModal={setMobileNotVerifiedShowModal}
                                                    setOnClickSubmit={() => sendOTP()}
                                                />
                                                <VerifyPhoneNumberModal
                                                    mobileNumber={mobileNumber}
                                                    showModal={verifyNumberShowModal}
                                                    setShowModal={setVerifyNumberShowModal}
                                                    setVerifyPhoneNumberSuccess={
                                                        setVerifyPhoneNumberSuccess
                                                    }
                                                />
                                                <div className="font-bold font-manrope text-lg mt-5">
                                                    Personal Details
                                                </div>
                                                <div className="flex flex-col my-6">
                                                    <div className="flex flex-row w-full items-center">
                                                        <img
                                                            className="rounded-full h-20 w-20"
                                                            src={`${
                                                                imageUrl === null
                                                                    ? '/assets/user.png'
                                                                    : imageUrl.includes('webUsers')
                                                                    ? s3UrlPrefix + imageUrl
                                                                    : imageUrl
                                                            }`}
                                                            alt="profile"
                                                        />
                                                        <div>
                                                            <div
                                                                className="flex flex-col ml-6"
                                                                style={{
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <label
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                    }}
                                                                    className="border-2 cursor-pointer font-semibold border-purple-800 bg-white text-purple-800 rounded w-40 h-10"
                                                                    htmlFor="files"
                                                                >
                                                                    Upload picture
                                                                </label>
                                                                <input
                                                                    id="files"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    className="hidden"
                                                                    onChange={e => {
                                                                        const file = [];
                                                                        file.push(e);
                                                                        handleImage(e);
                                                                        uploadImageToS3(e);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="font-medium text-gray-600 text-xs flex py-1 mt-4">
                                                        <input
                                                            className="mr-1 mt-1"
                                                            type="checkbox"
                                                            checked={enablePublicProfile}
                                                            onChange={() => {
                                                                setEnablePublicProfile(
                                                                    !enablePublicProfile
                                                                );
                                                            }}
                                                        />
                                                        <div>
                                                            I consent that my profile picture can be
                                                            shown in the leaderboards.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col mb-1 w-full">
                                                    <SmallFontLabel
                                                        className="flex flex-row font-manrope my-1"
                                                        htmlFor="tab_1"
                                                    >
                                                        My Public Profile URL
                                                    </SmallFontLabel>
                                                    <div
                                                        className={` pl-4 py-1 h-12 pt-3 whitespace-nowrap overflow-x-hidden inputFieldThickness`}
                                                        id="tab_1"
                                                    >{`${window.location.origin}/public-profile/${studentData?.id}`}</div>
                                                </div>
                                                <div className="flex flex-col mb-1 w-full">
                                                    <div>
                                                        <input
                                                            className="mr-1 mt-1"
                                                            id="public_profile_consent"
                                                            type="checkbox"
                                                            checked={isPublic}
                                                            onChange={() => {
                                                                setIsPublic(!isPublic);
                                                            }}
                                                        />
                                                        <label htmlFor="public_profile_consent">
                                                            Keep my profile publicly linked and
                                                            accessible through the BRIDGE website
                                                            leaderboard and via QR code on the
                                                            certificates.
                                                            {isPublic && (
                                                                <span className=" block font-bold text-gray-700">
                                                                    Your personal information and
                                                                    BRIDGE progress information will
                                                                    now be shared publicly.
                                                                </span>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">
                                                    <div className="flex flex-col my-1 w-full">
                                                        <SmallFontLabel
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            First Name
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </SmallFontLabel>
                                                        <input
                                                            className={`text-sm pl-2 py-1 h-12 ${
                                                                isErrorFirstName
                                                                    ? 'inputFieldErrorThickness'
                                                                    : 'inputFieldThickness'
                                                            }`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={firstName}
                                                            placeholder="Enter your first name"
                                                            autoComplete="off"
                                                            onChange={e => {
                                                                setFirstName(e.target.value);
                                                                if (e.target.value.length !== 0) {
                                                                    setIsErrorFirstName(false);
                                                                }
                                                            }}
                                                        />
                                                        {isErrorFirstName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                First Name is mandatory
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="flex flex-col my-1 w-full">
                                                        <SmallFontLabel
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Last Name
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </SmallFontLabel>
                                                        <input
                                                            className={`text-sm pl-2 py-1 h-12 ${
                                                                isErrorLastName
                                                                    ? 'inputFieldErrorThickness'
                                                                    : 'inputFieldThickness'
                                                            }`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={lastName}
                                                            placeholder="Enter your last name"
                                                            autoComplete="off"
                                                            onChange={e => {
                                                                setLastName(e.target.value);
                                                                if (e.target.value) {
                                                                    setIsErrorLastName(false);
                                                                }
                                                            }}
                                                        />
                                                        {isErrorLastName ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Last Name is mandatory
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-2">
                                                    <div className="flex flex-col my-1 w-full">
                                                        <SmallFontLabel
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Date of Birth
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </SmallFontLabel>
                                                        <div className="leading-normal">
                                                            <SmallFontLabel
                                                                htmlFor="set_date"
                                                                className="inputFieldThickness rounded h-12 flex flex-row w-full justify-between items-center"
                                                            >
                                                                <form autoComplete="off">
                                                                    <DatePicker
                                                                        required
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        yearDropdownItemNumber={112}
                                                                        scrollableYearDropdown
                                                                        maxDate={new Date()}
                                                                        id="set_date"
                                                                        className="text-sm p-1 h-7 pl-2"
                                                                        selected={dateOfBirth}
                                                                        onChange={date => {
                                                                            if (date != null) {
                                                                                setDateOfBirth(
                                                                                    date
                                                                                );
                                                                            }
                                                                            if (isErrorDOB) {
                                                                                setIsErrorDOB(
                                                                                    false
                                                                                );
                                                                            }
                                                                        }}
                                                                        dateFormat="do MMMM yyyy"
                                                                        placeholderText="Select your date of birth"
                                                                    />
                                                                </form>
                                                                <label
                                                                    className="pr-2"
                                                                    htmlFor="set_date"
                                                                >
                                                                    <HiIcons.HiCalendar />
                                                                </label>
                                                            </SmallFontLabel>
                                                            {isErrorDOB ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Select your date of birth
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col my-1 w-full">
                                                        <SmallFontLabel
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Gender
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </SmallFontLabel>
                                                        <div
                                                            className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                                isErrorGender
                                                                    ? 'inputFieldErrorThickness'
                                                                    : 'inputFieldThickness'
                                                            } `}
                                                        >
                                                            <div
                                                                className="flex justify-between w-full"
                                                                onClick={() =>
                                                                    setGenderDropdownVisible(true)
                                                                }
                                                            >
                                                                <div
                                                                    className={`text-sm pl-2 ${
                                                                        trackFilterGenderAction !==
                                                                        'Select your gender'
                                                                            ? 'text-gray-700'
                                                                            : 'text-gray-400'
                                                                    }`}
                                                                >
                                                                    {trackFilterGenderAction}
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex flex-row">
                                                                        <div className="flex flex-col">
                                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {genderDropdownVisible ? (
                                                                <ul
                                                                    className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    role="menu"
                                                                    onMouseLeave={() =>
                                                                        setGenderDropdownVisible(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {TrackGenderFilter.map(
                                                                        action => (
                                                                            <div
                                                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                                                key={action.id}
                                                                                onClick={() => {
                                                                                    setIsErrorGender(
                                                                                        false
                                                                                    );
                                                                                    setTrackFilterGenderAction(
                                                                                        action.val
                                                                                    );
                                                                                    setGenderDropdownVisible(
                                                                                        false
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {action.val}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            ) : null}
                                                        </div>
                                                        {isErrorGender ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Select a gender
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-col my-1 w-full">
                                                        <SmallFontLabel
                                                            className="flex my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            NIC / Passport Number
                                                        </SmallFontLabel>
                                                        <input
                                                            className={`text-sm rounded pl-2 py-1 h-12 inputFieldThickness`}
                                                            type="text"
                                                            id="tab_1"
                                                            value={nicNumber}
                                                            placeholder="Enter your NIC / Passport number"
                                                            autoComplete="off"
                                                            onChange={e => {
                                                                setNICNumber(e.target.value);
                                                                if (isErrorNIC) {
                                                                    setIsErrorNIC(false);
                                                                }
                                                            }}
                                                        />
                                                        {isErrorNIC ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                Invalid NIC
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="flex flex-col my-1 w-full">
                                                        <SmallFontLabel
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_mobile"
                                                        >
                                                            Mobile Number
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </SmallFontLabel>
                                                        <div>
                                                            <label
                                                                htmlFor="set_mobile"
                                                                className={`rounded h-12 flex flex-row w-full justify-between ${
                                                                    isErrorMobileNo
                                                                        ? 'inputFieldErrorThickness'
                                                                        : 'inputFieldThickness'
                                                                }  ${
                                                                    studentData?.contact
                                                                        ?.mobileNo !== null &&
                                                                    studentData?.contact
                                                                        ?.mobileNo !== undefined &&
                                                                    'pointer-events-none opacity-50'
                                                                }`}
                                                            >
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: '100%',
                                                                        height: '45px',
                                                                        fontSize: '14px',
                                                                        paddingLeft: '48px',
                                                                        borderRadius: '4px',
                                                                        border: 0,
                                                                        fontFamily: 'Lato',
                                                                    }}
                                                                    dropdownClass={
                                                                        'mobileNumberCountryDropdown'
                                                                    }
                                                                    inputClass="onboard-phone-input"
                                                                    country="lk"
                                                                    specialLabel={''}
                                                                    value={mobileNumber}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    placeholder="Enter your Mobile Number (Eg: +94712345678)"
                                                                    onChange={(e, country: any) => {
                                                                        if (!isNaN(Number(e))) {
                                                                            const Index =
                                                                                country.dialCode
                                                                                    .length;

                                                                            if (e[Index] === '0') {
                                                                                const modifiedNumber =
                                                                                    e.slice(
                                                                                        0,
                                                                                        Index
                                                                                    ) +
                                                                                    e.slice(
                                                                                        Index + 1
                                                                                    );

                                                                                setMobileNumber(
                                                                                    modifiedNumber
                                                                                );
                                                                            } else {
                                                                                setMobileNumber(e);
                                                                            }
                                                                            setIsErrorMobileNo(
                                                                                false
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                                {verifyPhoneNumberSuccess ||
                                                                (studentData?.contact?.mobileNo !==
                                                                    null &&
                                                                    studentData?.contact
                                                                        ?.mobileNo !==
                                                                        undefined) ? (
                                                                    <div className="mr-2 mt-0.5">
                                                                        <Verified />
                                                                    </div>
                                                                ) : verifyPhoneNumber ? (
                                                                    <div
                                                                        onClick={sendOTP}
                                                                        className="flex items-center mr-2 my-2 bg-green-450 text-white text-xs rounded-full px-3 w-max cursor-pointer"
                                                                    >
                                                                        Verify
                                                                    </div>
                                                                ) : null}
                                                            </label>
                                                        </div>

                                                        {isErrorMobileNo ? (
                                                            <div className="text-red-500 text-xs my-1">
                                                                {sendMobileVerificationFailed ? (
                                                                    sendMobileVerificationFailed.includes(
                                                                        'already exists'
                                                                    ) ? (
                                                                        <>
                                                                            Your mobile number
                                                                            already exists. Please{' '}
                                                                            <span
                                                                                className="text-blue-500 cursor-pointer underline"
                                                                                onClick={() => {
                                                                                    dispatch(
                                                                                        logoutWeb()
                                                                                    );
                                                                                    history.push({
                                                                                        pathname:
                                                                                            '/signin',
                                                                                        state: {
                                                                                            tab: 'byPhone',
                                                                                        },
                                                                                    });
                                                                                }}
                                                                            >
                                                                                sign in
                                                                            </span>{' '}
                                                                            with the mobile number.
                                                                        </>
                                                                    ) : (
                                                                        sendMobileVerificationFailed
                                                                    )
                                                                ) : (
                                                                    'Enter valid Mobile number'
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col my-1 w-full">
                                                    <div className="flex flex-row items-center">
                                                        <SmallFontLabel
                                                            className="flex flex-row my-1"
                                                            htmlFor="tab_1"
                                                        >
                                                            Email
                                                            <span className="text-red-600">
                                                                &nbsp;*
                                                            </span>
                                                        </SmallFontLabel>
                                                        <HiIcons.HiQuestionMarkCircle
                                                            onClick={() => {
                                                                setEmailHelpText(
                                                                    prevValue => !prevValue
                                                                );
                                                            }}
                                                            className="ml-2 mt-1 cursor-pointer"
                                                        />
                                                        {emailHelpText ? (
                                                            <div
                                                                className="bg-white border-2 rounded-md absolute z-10 px-4 py-2 mt-7 ml-16 text-xs"
                                                                style={{
                                                                    width: '275px',
                                                                }}
                                                            >
                                                                If you don’t have an email already,{' '}
                                                                <span className="border-b-2 border-gray-400 cursor-pointer">
                                                                    <a
                                                                        target="_blank"
                                                                        href={emailCreationURL}
                                                                        rel="noreferrer"
                                                                    >
                                                                        Click Here
                                                                    </a>
                                                                </span>{' '}
                                                                to learn more.
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <input
                                                        className={`text-sm pl-2 py-1 h-12 ${
                                                            isErrorEmail
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        } ${
                                                            studentData?.emailId !== null &&
                                                            studentData?.emailId !== undefined &&
                                                            'pointer-events-none opacity-50'
                                                        }`}
                                                        type="email"
                                                        id="tab_1"
                                                        value={values.email}
                                                        placeholder="Enter your email address"
                                                        autoComplete="off"
                                                        onChange={e => {
                                                            setFieldValue('email', e.target.value);
                                                            setEmail(e.target.value);
                                                            if (e.target.value) {
                                                                setIsErrorEmail(false);
                                                            }
                                                        }}
                                                    />
                                                    {errors.email ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {errors.email}
                                                        </div>
                                                    ) : null}
                                                    {isErrorEmail ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            {emailVerificationSuccessMessage}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <p className="font-bold font-manrope text-lg mt-4 mb-2">
                                                    Address Information
                                                </p>
                                                <div className="flex flex-col my-1">
                                                    <div
                                                        className="flex flex-row my-1"
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        Home town (District)
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  ${
                                                            isErrorHomeTown
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        }`}
                                                    >
                                                        <div
                                                            className="flex justify-between w-full"
                                                            onClick={() =>
                                                                setHometownDropdownVisible(true)
                                                            }
                                                        >
                                                            <div className="text-gray-700 font-medium pl-2">
                                                                {trackFilterAddressAction}
                                                            </div>
                                                            <div className="">
                                                                <div className="flex flex-row">
                                                                    {trackFilterAddressAction !==
                                                                    'Select your home town' ? (
                                                                        <HiIcons.HiOutlineX
                                                                            className="mr-2 mt-1"
                                                                            onClick={() =>
                                                                                setTrackFilterAddressAction(
                                                                                    'Select your home town'
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                    <div className="flex flex-col">
                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {hometownDropdownVisible ? (
                                                            <ul
                                                                id="hometwonId"
                                                                className="max-h-40 overflow-y-auto absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                role="menu"
                                                                onMouseLeave={() =>
                                                                    setHometownDropdownVisible(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                {TrackAddressFilter.map(action => (
                                                                    <div
                                                                        className="p-2 pl-2 hover:bg-gray-200 "
                                                                        key={action.id}
                                                                        onClick={() => {
                                                                            setIsErrorHomeTown(
                                                                                false
                                                                            );
                                                                            setTrackFilterAddressAction(
                                                                                action.val
                                                                            );
                                                                            setHometownDropdownVisible(
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        {action.val}
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                    {isErrorHomeTown ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Home town is mandatory
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div
                                                    style={{ marginTop: `${belowHometown}px` }}
                                                    className="flex flex-col  w-full md:w-2/5"
                                                >
                                                    <SmallFontLabel
                                                        className="flex flex-row "
                                                        htmlFor="tab_1"
                                                    >
                                                        Postal Address
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </SmallFontLabel>
                                                    <input
                                                        className={`text-sm pl-2 py-1 h-12 ${
                                                            isErrorAddress
                                                                ? 'inputFieldErrorThickness'
                                                                : 'inputFieldThickness'
                                                        }`}
                                                        type="text"
                                                        id="tab_1"
                                                        value={address}
                                                        placeholder="Enter the postal address"
                                                        autoComplete="off"
                                                        onChange={e => {
                                                            setAddress(e.target.value);
                                                            if (e.target.value) {
                                                                setIsErrorAddress(false);
                                                            }
                                                        }}
                                                    />
                                                    {isErrorAddress ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Postal address is mandatory
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="flex mb-4 mt-16">
                                                    <button
                                                        className="bg-purple-950 text-white rounded w-full h-12"
                                                        onClick={() => {
                                                            PersonalDetailsCheck();
                                                        }}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                        {onBoardFlow === 'education' ? (
                                            <div className="px-4">
                                                <div className="font-bold font-manrope text-lg mt-5">
                                                    Educational Details
                                                </div>
                                                <div className="font-bold font-manrope mt-4">
                                                    Select your Secondary School education level
                                                    <span className="text-red-600">&nbsp;*</span>
                                                </div>
                                                <div className="mt-5">
                                                    <div className="mb-2">
                                                        <input
                                                            id="1"
                                                            className="newone"
                                                            type="radio"
                                                            value="I have not done O/Ls yet"
                                                            checked={
                                                                secondaryEd ===
                                                                'I have not done O/Ls yet'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3 text-xs" htmlFor="1">
                                                            I have not done O/Ls yet
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="2"
                                                            className="newone"
                                                            type="radio"
                                                            value="I have completed O/Ls but not A/Ls"
                                                            checked={
                                                                secondaryEd ===
                                                                `I have completed O/Ls but not A/Ls`
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3 text-xs" htmlFor="2">
                                                            I have completed O/Ls but not A/Ls
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="3"
                                                            className="newone"
                                                            type="radio"
                                                            value="I have completed A/Ls"
                                                            checked={
                                                                secondaryEd ===
                                                                'I have completed A/Ls'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3 text-xs" htmlFor="3">
                                                            I have completed A/Ls
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="4"
                                                            className="newone"
                                                            type="radio"
                                                            value="I am doing higher studies but not an undergraduate"
                                                            checked={
                                                                secondaryEd ===
                                                                'I am doing higher studies but not an undergraduate'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3 text-xs" htmlFor="4">
                                                            I am doing higher studies but not an
                                                            undergraduate
                                                        </label>
                                                    </div>
                                                    <div className="mb-2">
                                                        <input
                                                            id="5"
                                                            className="newone"
                                                            type="radio"
                                                            value="I am an undergraduate"
                                                            checked={
                                                                secondaryEd ===
                                                                'I am an undergraduate'
                                                            }
                                                            onChange={e => {
                                                                setSecondaryEd(e.target.value);
                                                                setIsErrorEducationStatus(false);
                                                            }}
                                                        />
                                                        <label className="ml-3 text-xs" htmlFor="5">
                                                            I am an undergraduate
                                                        </label>
                                                    </div>
                                                    {isErrorEducationStatus ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your secondary education level
                                                        </div>
                                                    ) : null}
                                                </div>

                                                {secondaryEd !== 'I have not done O/Ls yet' ? (
                                                    <>
                                                        {secondaryEd !==
                                                        'I have completed O/Ls but not A/Ls' ? (
                                                            <>
                                                                <div>
                                                                    <div className="font-bold font-manrope mt-8 mb-5">
                                                                        Stream of study in Advanced
                                                                        Level
                                                                    </div>

                                                                    <div className="flex flex-col mt-3 mb-1 w-full">
                                                                        <SmallFontLabel
                                                                            className="flex flex-row my-1"
                                                                            htmlFor="tab_1"
                                                                        >
                                                                            Advanced Level Stream
                                                                            <span className="text-red-600">
                                                                                {' '}
                                                                                *
                                                                            </span>
                                                                        </SmallFontLabel>

                                                                        <div
                                                                            className={`flex relative cursor-pointer items-center focus:outline-none h-12 md:p-2 text-sm focus:border-blue-900 ${
                                                                                isErrorStream
                                                                                    ? 'inputFieldErrorThickness'
                                                                                    : 'inputFieldThickness'
                                                                            } `}
                                                                        >
                                                                            <div
                                                                                className="flex justify-between w-full"
                                                                                onClick={() =>
                                                                                    setStreamDropdownVisible(
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={`text-sm pl-2 ${
                                                                                        AlStreamFilterAction !==
                                                                                        'Select your gender'
                                                                                            ? 'text-gray-700'
                                                                                            : 'text-gray-400'
                                                                                    }`}
                                                                                >
                                                                                    {
                                                                                        AlStreamFilterAction
                                                                                    }
                                                                                </div>
                                                                                <div className="">
                                                                                    <div className="flex flex-row">
                                                                                        <div className="flex flex-col">
                                                                                            <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                            <HiIcons.HiChevronDown className="mr-2" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {streamDropdownVisible ? (
                                                                                <ul
                                                                                    className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                    role="menu"
                                                                                    onMouseLeave={() =>
                                                                                        setStreamDropdownVisible(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {AlStreamFilter.map(
                                                                                        action => (
                                                                                            <div
                                                                                                className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                key={
                                                                                                    action.id
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    setIsErrorStream(
                                                                                                        false
                                                                                                    );
                                                                                                    setAlStreamFilterAction(
                                                                                                        action.val
                                                                                                    );
                                                                                                    setStream(
                                                                                                        action.val
                                                                                                    );
                                                                                                    setStreamDropdownVisible(
                                                                                                        false
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    action.val
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    {isErrorStream && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            Select your A/L Stream
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : null}

                                                        <div>
                                                            <div className="font-bold font-manrope mt-8">
                                                                Are you currently pursuing Higher
                                                                Education?
                                                                <span className="text-red-600">
                                                                    &nbsp;*
                                                                </span>
                                                            </div>
                                                            <div className="mt-5">
                                                                <div className="mb-2">
                                                                    <input
                                                                        id="higherEd1"
                                                                        className="newone"
                                                                        type="radio"
                                                                        value="Yes"
                                                                        checked={
                                                                            isPursuingHigherEducation ===
                                                                            'Yes'
                                                                        }
                                                                        onChange={e => {
                                                                            setIsPursuingHigherEducation(
                                                                                e.target.value
                                                                            );
                                                                            setPursuingHigherStudies(
                                                                                1
                                                                            );
                                                                            setIsErrorPursueHigherEducation(
                                                                                false
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="ml-3 text-xs"
                                                                        htmlFor="higherEd1"
                                                                    >
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <input
                                                                        id="higherEd2"
                                                                        className="newone"
                                                                        type="radio"
                                                                        value="No"
                                                                        checked={
                                                                            isPursuingHigherEducation ===
                                                                            'No'
                                                                        }
                                                                        onChange={e => {
                                                                            setIsPursuingHigherEducation(
                                                                                e.target.value
                                                                            );
                                                                            setPursuingHigherStudies(
                                                                                0
                                                                            );
                                                                            setIsErrorPursueHigherEducation(
                                                                                false
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="ml-3 text-xs"
                                                                        htmlFor="higherEd2"
                                                                    >
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {isErrorPursueHigherEducation ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Select your answer
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {isPursuingHigherEducation === 'Yes' ? (
                                                            <>
                                                                <div className="font-bold font-manrope mt-8 mb-5">
                                                                    Institute Details
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <SmallFontDiv className="flex flex-row mb-1">
                                                                        Institute Name
                                                                        <span className="text-red-600">
                                                                            &nbsp;*
                                                                        </span>
                                                                    </SmallFontDiv>
                                                                    <div className="flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  inputFieldThickness">
                                                                        <div
                                                                            className="flex justify-between w-full"
                                                                            onClick={() =>
                                                                                setDropdownVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            <div className="text-gray-700 font-medium pl-2 ">
                                                                                {trackFilterAction}
                                                                            </div>
                                                                            <div className="">
                                                                                <div className="flex flex-row">
                                                                                    {trackFilterAction !==
                                                                                    'Select your institute' ? (
                                                                                        <HiIcons.HiOutlineX
                                                                                            className="mr-2 mt-1"
                                                                                            onClick={() => {
                                                                                                setTrackFilterAction(
                                                                                                    'Select a institute'
                                                                                                );
                                                                                                setInstitute(
                                                                                                    null
                                                                                                );
                                                                                                setInstituteID(
                                                                                                    null
                                                                                                );
                                                                                                setcat1FilterAction(
                                                                                                    'Select'
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    ) : null}
                                                                                    <div className="flex flex-col">
                                                                                        <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                        <HiIcons.HiChevronDown className="mr-2" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {dropdownVisible ? (
                                                                            <ul
                                                                                className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-40 overflow-y-scroll"
                                                                                role="menu"
                                                                                onMouseLeave={() =>
                                                                                    setDropdownVisible(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            >
                                                                                {instituteData?.map(
                                                                                    action => (
                                                                                        <div
                                                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                                                            key={
                                                                                                action.id
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setIsErrorInstituteName(
                                                                                                    false
                                                                                                );
                                                                                                setTrackFilterAction(
                                                                                                    action.instName
                                                                                                );
                                                                                                setDropdownVisible(
                                                                                                    false
                                                                                                );
                                                                                                setInstitute(
                                                                                                    action.instName
                                                                                                );
                                                                                                setInstituteID(
                                                                                                    action.id
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                action.instName
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        ) : null}
                                                                    </div>
                                                                    {isErrorInstituteName && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            Select your Institute
                                                                            Name
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                {institue !== null
                                                                    ? selectedInstitue?.map(
                                                                          data => (
                                                                              <div key={data?.id}>
                                                                                  {isCat1 ? (
                                                                                      <div className="flex flex-col my-2">
                                                                                          <SmallFontDiv className="flex flex-row mb-1">
                                                                                              {
                                                                                                  data
                                                                                                      ?.instCategory1
                                                                                                      ?.name
                                                                                              }
                                                                                          </SmallFontDiv>
                                                                                          <div
                                                                                              className={`flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900 inputFieldThickness `}
                                                                                          >
                                                                                              <div
                                                                                                  className="flex justify-between w-full"
                                                                                                  onClick={() =>
                                                                                                      setInstitueCat1DropDownVisible(
                                                                                                          true
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <div className="text-gray-700 font-medium pl-2">
                                                                                                      {
                                                                                                          cat1FilterAction
                                                                                                      }
                                                                                                  </div>
                                                                                                  <div className="">
                                                                                                      <div className="flex flex-row">
                                                                                                          {cat1FilterAction !==
                                                                                                          'Select' ? (
                                                                                                              <HiIcons.HiOutlineX
                                                                                                                  className="mr-2 mt-1"
                                                                                                                  onClick={() =>
                                                                                                                      setcat1FilterAction(
                                                                                                                          'Select'
                                                                                                                      )
                                                                                                                  }
                                                                                                              />
                                                                                                          ) : null}
                                                                                                          <div className="flex flex-col">
                                                                                                              <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                              <HiIcons.HiChevronDown className="mr-2" />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                              {institueCat1DropDownVisible ? (
                                                                                                  <ul
                                                                                                      className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                                      role="menu"
                                                                                                      onMouseLeave={() =>
                                                                                                          setInstitueCat1DropDownVisible(
                                                                                                              false
                                                                                                          )
                                                                                                      }
                                                                                                  >
                                                                                                      {data?.instCategory1?.options.map(
                                                                                                          action => (
                                                                                                              <div
                                                                                                                  className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                                  key={
                                                                                                                      action
                                                                                                                  }
                                                                                                                  onClick={() => {
                                                                                                                      setcat1FilterAction(
                                                                                                                          action
                                                                                                                      );
                                                                                                                      setInstitueCat1DropDownVisible(
                                                                                                                          false
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      action
                                                                                                                  }
                                                                                                              </div>
                                                                                                          )
                                                                                                      )}
                                                                                                  </ul>
                                                                                              ) : null}
                                                                                          </div>
                                                                                      </div>
                                                                                  ) : null}

                                                                                  {isCat2 ? (
                                                                                      <div className="flex flex-col my-2">
                                                                                          <SmallFontDiv className="flex flex-row mb-1">
                                                                                              {
                                                                                                  data
                                                                                                      ?.instCategory2
                                                                                                      ?.name
                                                                                              }
                                                                                          </SmallFontDiv>
                                                                                          <div
                                                                                              className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                                          >
                                                                                              <div
                                                                                                  className="flex justify-between w-full"
                                                                                                  onClick={() =>
                                                                                                      setInstitueCat2DropDownVisible(
                                                                                                          true
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <div className="text-gray-700 font-medium pl-2">
                                                                                                      {
                                                                                                          cat2FilterAction
                                                                                                      }
                                                                                                  </div>
                                                                                                  <div className="">
                                                                                                      <div className="flex flex-row">
                                                                                                          {cat2FilterAction !==
                                                                                                          'Select' ? (
                                                                                                              <HiIcons.HiOutlineX
                                                                                                                  className="mr-2 mt-1"
                                                                                                                  onClick={() =>
                                                                                                                      setcat2FilterAction(
                                                                                                                          'Select'
                                                                                                                      )
                                                                                                                  }
                                                                                                              />
                                                                                                          ) : null}
                                                                                                          <div className="flex flex-col">
                                                                                                              <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                              <HiIcons.HiChevronDown className="mr-2" />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                              {institueCat2DropDownVisible ? (
                                                                                                  <ul
                                                                                                      className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                                      role="menu"
                                                                                                      onMouseLeave={() =>
                                                                                                          setInstitueCat2DropDownVisible(
                                                                                                              false
                                                                                                          )
                                                                                                      }
                                                                                                  >
                                                                                                      {data?.instCategory2?.options.map(
                                                                                                          action => (
                                                                                                              <div
                                                                                                                  className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                                  key={
                                                                                                                      action
                                                                                                                  }
                                                                                                                  onClick={() => {
                                                                                                                      setcat2FilterAction(
                                                                                                                          action
                                                                                                                      );
                                                                                                                      setInstitueCat2DropDownVisible(
                                                                                                                          false
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      action
                                                                                                                  }
                                                                                                              </div>
                                                                                                          )
                                                                                                      )}
                                                                                                  </ul>
                                                                                              ) : null}
                                                                                          </div>
                                                                                      </div>
                                                                                  ) : null}

                                                                                  {isCat3 ? (
                                                                                      <div className="flex flex-col my-2">
                                                                                          <SmallFontDiv className="flex flex-row mb-1">
                                                                                              {
                                                                                                  data
                                                                                                      ?.instCategory3
                                                                                                      ?.name
                                                                                              }
                                                                                          </SmallFontDiv>
                                                                                          <div
                                                                                              className={`rounded-md flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 border-2 text-sm focus:border-blue-900 inputFieldThickness`}
                                                                                          >
                                                                                              <div
                                                                                                  className="flex justify-between w-full"
                                                                                                  onClick={() =>
                                                                                                      setInstitueCat3DropDownVisible(
                                                                                                          true
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <div className="text-gray-700 font-medium pl-2">
                                                                                                      {
                                                                                                          cat3FilterAction
                                                                                                      }
                                                                                                  </div>
                                                                                                  <div className="">
                                                                                                      <div className="flex flex-row">
                                                                                                          {cat3FilterAction !==
                                                                                                          'Select' ? (
                                                                                                              <HiIcons.HiOutlineX
                                                                                                                  className="mr-2 mt-1"
                                                                                                                  onClick={() =>
                                                                                                                      setcat3FilterAction(
                                                                                                                          'Select'
                                                                                                                      )
                                                                                                                  }
                                                                                                              />
                                                                                                          ) : null}
                                                                                                          <div className="flex flex-col">
                                                                                                              <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                                              <HiIcons.HiChevronDown className="mr-2" />
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                              {institueCat3DropDownVisible ? (
                                                                                                  <ul
                                                                                                      className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-10 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                                                      role="menu"
                                                                                                      onMouseLeave={() =>
                                                                                                          setInstitueCat3DropDownVisible(
                                                                                                              false
                                                                                                          )
                                                                                                      }
                                                                                                  >
                                                                                                      {data?.instCategory3?.options.map(
                                                                                                          action => (
                                                                                                              <div
                                                                                                                  className="p-2 pl-2 hover:bg-gray-200 "
                                                                                                                  key={
                                                                                                                      action
                                                                                                                  }
                                                                                                                  onClick={() => {
                                                                                                                      setcat3FilterAction(
                                                                                                                          action
                                                                                                                      );
                                                                                                                      setInstitueCat3DropDownVisible(
                                                                                                                          false
                                                                                                                      );
                                                                                                                  }}
                                                                                                              >
                                                                                                                  {
                                                                                                                      action
                                                                                                                  }
                                                                                                              </div>
                                                                                                          )
                                                                                                      )}
                                                                                                  </ul>
                                                                                              ) : null}
                                                                                          </div>
                                                                                      </div>
                                                                                  ) : null}
                                                                              </div>
                                                                          )
                                                                      )
                                                                    : null}

                                                                <div className="flex flex-col mt-2 mb-1 w-full">
                                                                    <SmallFontLabel
                                                                        className="flex flex-row my-1"
                                                                        htmlFor="tab_1"
                                                                    >
                                                                        Student Registration
                                                                        Number/Student ID given by
                                                                        your Institute
                                                                        <span className="text-red-600">
                                                                            &nbsp;*
                                                                        </span>
                                                                    </SmallFontLabel>
                                                                    <input
                                                                        className="text-sm pl-2 py-1 h-12 inputFieldThickness"
                                                                        type="text"
                                                                        id="tab_1"
                                                                        value={batchNumber}
                                                                        placeholder="Enter your Student Registration Number/Student ID"
                                                                        onChange={e => {
                                                                            setIsErrorInstituteRegId(
                                                                                false
                                                                            );
                                                                            setBatchNumber(
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                    />
                                                                    {isErrorInstituteRegId && (
                                                                        <div className="text-red-500 text-xs my-1">
                                                                            Enter your Student
                                                                            Registration Number
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                                <div className="flex flex-col mt-8 mb-5 w-full font-bold font-manrope">
                                                    BRIDGE invitation code given by your Institute
                                                    (optional)
                                                    <input
                                                        className={`inputFieldThickness pl-2 py-1 h-12 border-gray-300 mt-4 `}
                                                        type="text"
                                                        id="tab_1"
                                                        value={bridgeNumber}
                                                        placeholder="Enter the BRIDGE invitation code given by your Institute"
                                                        onChange={e => {
                                                            setBridgeNumber(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="font-bold font-manrope mt-8">
                                                        I joined Future Careers BRIDGE program
                                                        because,
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="mb-2">
                                                            <input
                                                                id="futurecar1"
                                                                className="newone"
                                                                type="radio"
                                                                value="Only interested in studying at this time"
                                                                checked={
                                                                    joinedFutureCareerBridgeReason ===
                                                                    'Only interested in studying at this time'
                                                                }
                                                                onChange={e => {
                                                                    setJoinedFutureCareerBridgeReason(
                                                                        e.target.value
                                                                    );
                                                                    setIsErrorrReasonForFCB(false);
                                                                    setAcademicOnly(true);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3 text-xs"
                                                                htmlFor="futurecar1"
                                                            >
                                                                Only interested in studying at this
                                                                time
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="futurecar2"
                                                                className="newone"
                                                                type="radio"
                                                                value="Looking for an industry internship"
                                                                checked={
                                                                    joinedFutureCareerBridgeReason ===
                                                                    'Looking for an industry internship'
                                                                }
                                                                onChange={e => {
                                                                    setJoinedFutureCareerBridgeReason(
                                                                        e.target.value
                                                                    );
                                                                    setIsErrorrReasonForFCB(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3 text-xs"
                                                                htmlFor="futurecar2"
                                                            >
                                                                Looking for an industry internship
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="futurecar3"
                                                                className="newone"
                                                                type="radio"
                                                                value="Already in employment but looking for new Tech career"
                                                                checked={
                                                                    joinedFutureCareerBridgeReason ===
                                                                    'Already in employment but looking for new Tech career'
                                                                }
                                                                onChange={e => {
                                                                    setJoinedFutureCareerBridgeReason(
                                                                        e.target.value
                                                                    );
                                                                    setIsErrorrReasonForFCB(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3 text-xs"
                                                                htmlFor="futurecar3"
                                                            >
                                                                Already in employment but looking
                                                                for new Tech career
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isErrorrReasonForFCB ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your answer
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div>
                                                    <div className="font-bold font-manrope mt-8">
                                                        Are you presently employed (full time or
                                                        part time)?
                                                        <span className="text-red-600">
                                                            &nbsp;*
                                                        </span>
                                                    </div>
                                                    <div className="mt-5">
                                                        <div className="mb-2">
                                                            <input
                                                                id="employed1"
                                                                className="newone"
                                                                type="radio"
                                                                value="No"
                                                                checked={isEmployed === 'No'}
                                                                onChange={e => {
                                                                    setIsEmployed(e.target.value);
                                                                    setIsErrorEmployed(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3 text-xs"
                                                                htmlFor="employed1"
                                                            >
                                                                No
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="employed2"
                                                                className="newone"
                                                                type="radio"
                                                                value="Yes, at a company"
                                                                checked={
                                                                    isEmployed ===
                                                                    'Yes, at a company'
                                                                }
                                                                onChange={e => {
                                                                    setIsEmployed(e.target.value);
                                                                    setIsErrorEmployed(false);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3 text-xs"
                                                                htmlFor="employed2"
                                                            >
                                                                Yes, at a company
                                                            </label>
                                                        </div>
                                                        <div className="mb-2">
                                                            <input
                                                                id="employed3"
                                                                className="newone"
                                                                type="radio"
                                                                value="Yes, self-employed"
                                                                checked={
                                                                    isEmployed ===
                                                                    'Yes, self-employed'
                                                                }
                                                                onChange={e => {
                                                                    setIsEmployed(e.target.value);
                                                                }}
                                                            />
                                                            <label
                                                                className="ml-3 text-xs"
                                                                htmlFor="employed3"
                                                            >
                                                                Yes, self-employed
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isErrorEmployed ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select your answer
                                                        </div>
                                                    ) : null}
                                                </div>

                                                {isEmployed === 'Yes, at a company' ? (
                                                    <>
                                                        <div className="font-bold font-manrope  mt-8 mb-5">
                                                            Company Details
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <SmallFontDiv className="flex flex-row mb-1">
                                                                Company name
                                                                <span className="text-red-600">
                                                                    &nbsp;*
                                                                </span>
                                                            </SmallFontDiv>
                                                            <div className="flex relative cursor-pointer items-center focus:outline-none w-full md:w-3/4 md:p-2 h-12 text-sm focus:border-blue-900  inputFieldThickness">
                                                                <div
                                                                    className="flex justify-between w-full "
                                                                    onClick={() =>
                                                                        setCompanyDropdownVisible(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="text-gray-700 font-medium pl-2">
                                                                        {companyFilterAction}
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="flex flex-row">
                                                                            {companyFilterAction !==
                                                                            'Select your company' ? (
                                                                                <HiIcons.HiOutlineX
                                                                                    className="mr-2 mt-1"
                                                                                    onClick={() => {
                                                                                        setCompanyFilterAction(
                                                                                            'Select a company'
                                                                                        );
                                                                                        setCompany(
                                                                                            null
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            ) : null}
                                                                            <div className="flex flex-col">
                                                                                <HiIcons.HiChevronUp className="mr-2 -mb-2" />
                                                                                <HiIcons.HiChevronDown className="mr-2" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {companyDropdownVisible ? (
                                                                    <ul
                                                                        className="absolute w-full cursor-pointer pl-0 top-10 left-0 z-50 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-40 overflow-y-scroll"
                                                                        role="menu"
                                                                        onMouseLeave={() =>
                                                                            setCompanyDropdownVisible(
                                                                                false
                                                                            )
                                                                        }
                                                                    >
                                                                        {companyData?.map(
                                                                            action => (
                                                                                <div
                                                                                    className="p-2 pl-2 hover:bg-gray-200 "
                                                                                    key={action.id}
                                                                                    onClick={() => {
                                                                                        setCompanyFilterAction(
                                                                                            action.comName
                                                                                        );
                                                                                        setCompanyDropdownVisible(
                                                                                            false
                                                                                        );
                                                                                        setCompany(
                                                                                            action.comName
                                                                                        );
                                                                                        setIsErrorCompany(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {action.comName}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        <div
                                                                            className="p-2 pl-2 hover:bg-gray-200 "
                                                                            onClick={() => {
                                                                                setCompany(null);
                                                                                setCompanyFilterAction(
                                                                                    'Others'
                                                                                );
                                                                                setCompanyDropdownVisible(
                                                                                    false
                                                                                );
                                                                                setIsErrorCompany(
                                                                                    false
                                                                                );
                                                                            }}
                                                                        >
                                                                            Others
                                                                        </div>
                                                                    </ul>
                                                                ) : null}
                                                            </div>
                                                            {isErrorCompany ? (
                                                                <div className="text-red-500 text-xs my-1">
                                                                    Select your company
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {companyFilterAction === 'Others' ? (
                                                            <div>
                                                                <div className="flex flex-col mt-5 mb-1 w-full">
                                                                    <SmallFontLabel
                                                                        className="flex flex-row my-1"
                                                                        htmlFor="tab_1"
                                                                    >
                                                                        Enter your Company name
                                                                        <span className="text-red-600">
                                                                            &nbsp;*
                                                                        </span>
                                                                    </SmallFontLabel>
                                                                    <input
                                                                        className={`text-sm pl-2 py-1 h-12 ${
                                                                            isErrorFirstName
                                                                                ? 'inputFieldErrorThickness'
                                                                                : 'inputFieldThickness'
                                                                        }`}
                                                                        type="text"
                                                                        id="tab_1"
                                                                        value={otherCompany}
                                                                        placeholder="Company name"
                                                                        onChange={e => {
                                                                            setCompany(
                                                                                e.target.value
                                                                            );
                                                                            setOtherCompany(
                                                                                e.target.value
                                                                            );
                                                                            if (
                                                                                e.target.value
                                                                                    .length > 0
                                                                            ) {
                                                                                setIsErrorOtherCompany(
                                                                                    false
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {isErrorOtherCompany ? (
                                                                    <div className="text-red-500 text-xs my-1">
                                                                        Company name is mandatory
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : null}
                                                    </>
                                                ) : null}

                                                <div className="flex flex-col justify-between mb-4 pb-6 gap-4 mt-16">
                                                    <button
                                                        className="border-2 border-purple-950 bg-white text-purple-950 rounded w-full h-12"
                                                        onClick={() => {
                                                            setOnboardFlow('personal-details');
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        className="bg-purple-950 text-white rounded w-full h-12"
                                                        onClick={() => {
                                                            educationDetailsChecked();
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}

                                        {onBoardFlow === 'field-subscribe' ? (
                                            <div className="px-4">
                                                <div className="font-bold font-manrope text-lg mt-5">
                                                    Interests and Skills
                                                </div>
                                                <p className="mt-3 text-xs">
                                                    Tell us your areas of interests and skills, so
                                                    that we can recommend you a career path.
                                                </p>
                                                <p className="font-bold font-manrope mt-3">
                                                    Please select at least 3 from below.
                                                </p>
                                                <div className="flex flex-col mt-2">
                                                    <div className="flex flex-wrap mr-4">
                                                        {interestTag?.map(action => (
                                                            <div
                                                                style={{
                                                                    maxHeight: '10px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                key={action.id}
                                                                className={`rounded-full border-2 my-1 mr-4 cursor-pointer ${
                                                                    selectedTags?.includes(
                                                                        action.tagName
                                                                    )
                                                                        ? ' text-white bg-purple-950 border-purple-950'
                                                                        : 'text-purple-950 border-purple-950 font-medium'
                                                                }  py-3 px-2  hover:bg-purple-950 hover:text-white`}
                                                                onClick={() => {
                                                                    if (
                                                                        !selectedTags?.includes(
                                                                            action.tagName
                                                                        )
                                                                    ) {
                                                                        setInterestedTags([
                                                                            ...interestedTags,
                                                                            action,
                                                                        ]);
                                                                    }
                                                                }}
                                                            >
                                                                {action.tagName}
                                                                {selectedTags?.includes(
                                                                    action.tagName
                                                                ) ? (
                                                                    <HiIcons.HiOutlineX
                                                                        style={{ color: 'white' }}
                                                                        className="ml-2"
                                                                        onClick={() =>
                                                                            setInterestedTags(
                                                                                interestedTags?.filter(
                                                                                    item =>
                                                                                        item.tagName !==
                                                                                        action.tagName
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {isErrorInterestedField ? (
                                                        <div className="text-red-500 text-xs my-1">
                                                            Select at least 3 from above to complete
                                                            the registration process
                                                        </div>
                                                    ) : null}

                                                    <div className="flex flex-col justify-between mb-16 pb-6 mt-6 flex-wrap gap-4">
                                                        <button
                                                            className="border-2 border-purple-950 bg-white text-purple-950 rounded w-full h-12"
                                                            onClick={() => {
                                                                setOnboardFlow('education');
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            Back
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="bg-purple-950 text-white rounded w-full h-12"
                                                            onClick={() => {
                                                                handleSubmit();
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            Complete
                                                        </button>
                                                    </div>

                                                    <Modal isOpen={showModal} style={customStyles}>
                                                        <>
                                                            <div className="flex-shrink justify-center  items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
                                                                <div className="relative w-full my-6">
                                                                    {/* content */}
                                                                    {isEditLoading ? (
                                                                        <Loader />
                                                                    ) : StudentUpdateSuccessMessage ? (
                                                                        <div
                                                                            style={{
                                                                                maxHeight: '270px',
                                                                            }}
                                                                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    top: '-90px',
                                                                                }}
                                                                                className="flex flex-col -bottom-2"
                                                                            >
                                                                                <>
                                                                                    <span className="successTick self-center"></span>
                                                                                    <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                                                        Congratulations!
                                                                                    </h1>
                                                                                    <p className="text-center my-2 font-semibold">
                                                                                        We have
                                                                                        created your
                                                                                        BRIDGE
                                                                                        account now.
                                                                                    </p>
                                                                                    <div className="flex justify-center mt-3">
                                                                                        <button
                                                                                            className="bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                                                            onClick={() => {
                                                                                                window.location.href = `/dashBoard/learn`;
                                                                                            }}
                                                                                        >
                                                                                            Continue
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                maxHeight: '270px',
                                                                            }}
                                                                            className="border-2  rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12 py-6"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    top: '-90px',
                                                                                }}
                                                                                className="flex flex-col -bottom-2"
                                                                            >
                                                                                <>
                                                                                    <span className="successTick self-center"></span>
                                                                                    <h1 className="mb-2 mt-3 text-3xl text-center font-bold">
                                                                                        Sorry!
                                                                                    </h1>
                                                                                    <p className="text-center my-2 font-semibold">
                                                                                        Something
                                                                                        went
                                                                                        wrong...
                                                                                    </p>
                                                                                    <div className="flex justify-center mt-3">
                                                                                        <button
                                                                                            className=" bg-purple-950 text-white font-semibold rounded w-40 h-10 "
                                                                                            onClick={() => {
                                                                                                window.location.href = `/onboard`;
                                                                                            }}
                                                                                        >
                                                                                            Try
                                                                                            again
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </Modal>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
