import React from 'react';
import { useHistory } from 'react-router-dom';

const TermsAndConditions = () => {
    const history = useHistory();
    return (
        <div className="px-32 my-24">
            <div className="font-manrope font-bold text-2xl-2 text-purple-950 mb-14">
                Terms, conditions and privacy policy
            </div>
            <div className="mb-16">
                <ul style={{ listStyleType: 'disc' }} className="text-xl space-y-8 ml-6">
                    <li>
                        Please note that we share your learning details (including personal
                        information) with our partnered companies.
                    </li>
                    <li>
                        Any of your information may be used by SLASSCOM for its analysis purposes.
                    </li>
                    <li>
                        Your student ID and marks (but NO personal information) will be visible to
                        the public at our leaderboard page.
                    </li>
                    <li>
                        Our ability of providing a trainee job opportunity depends on the
                        availability of job vacancies by the time you complete challenges and your
                        performance at the job interviews. There is no guarantee provided by
                        SLASSCOM.
                    </li>
                    <li>
                        Please read and understand the FAQ section (not visible on mobile, please
                        use a computer) for other terms and conditions.
                    </li>
                </ul>
            </div>
            <div
                className="rounded ml-1 mb-2 font-semibold text-white bg-purple-800 cursor-pointer h-12 w-36 flex justify-center items-center"
                onClick={() => history.push('/home')}
            >
                Go Home
            </div>
        </div>
    );
};

export default TermsAndConditions;
