import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom";

interface paramsType {
    challengeID: string;
}

const NextChallenge = () => {
    
    const history = useHistory();
    const { challengeID } = useParams<paramsType>();

    useEffect(() => {
        setTimeout(() => {
            history.push(`/dashBoard/learn/Challenges/${challengeID}`);
        }, 1000);
    },[]);

    return (<div></div>);
}

export default NextChallenge;